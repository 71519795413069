import firebase from "firebase";
import "firebase/database"; 

export function getActivosGarantias(db_ref_base_inventario, user, callback) {
    console.log("<·><><·> getActivosGarantias() <·><><·>");
    
    let db_ref_inventario = db_ref_base_inventario;

    // let multipleSection = false;
    // if(user.type == 1){
    //     multipleSection = true;
    // }
    // else if(user.grants){
    //     if(user.grants.proyect == '*'){
    //         multipleSection = true;
    //     }
    //     else{
    //         db_ref_inventario = db_ref_base_inventario + '/' + user.grants.proyect;
    //     }
    // }

    // // multipleSection = true;
    // // console.log("multipleSection: ", multipleSection);
    // // console.log("user.type: ", user.type);

    var globalConf = firebase.database().ref(db_ref_inventario); 
    globalConf.on("value", (snapshot) => {
        let list = [];
        let i = 0;
        snapshot.forEach((childSnapshot) => {
            // if(multipleSection){
            //     childSnapshot.forEach((childElementSnapshot) => {
            //         list.push({ key: childElementSnapshot.key, itemN: ++i, ...childElementSnapshot.val() });
            //     });
            // }
            // else {
                // console.log(" >>>>>> " + JSON.stringify(ArrayDB));
                list.push({ key: childSnapshot.key, itemN: ++i, ...childSnapshot.val() });
            // }
        });
        // this.fullTable.forEach(x => { console.log(" --- " + JSON.stringify(x))});
        callback(list);
    });
}

export function getActivosGarantiasPorVencer(db_ref_inventario, limitTo, callback) {
    console.log("<·><><·> getActivosGarantiasPorVencer() <·><><·>");
    var globalConf = firebase.database().ref(db_ref_inventario);
    //ToDo: guardar todas las fechas como int para permitir ordenar por fecha
    globalConf.orderByChild("expiracionGarantia").limitToFirst(limitTo).on("value", (snapshot) => {
        let list = [];
        let i = 0;    
        snapshot.forEach((childSnapshot) => {
            // console.log(" >>>>>> " + JSON.stringify(ArrayDB));
            list.push({ key: childSnapshot.key, itemN: ++i, ...childSnapshot.val() });
        });
        // this.fullTable.forEach(x => { console.log(" --- " + JSON.stringify(x))});
        callback(list);
    });
}


// export function setAgentesGarantias(db_ref_inventario) {
//     console.log("<·><><·> setAgentesGarantias() <·><><·>");
//     let list = ['Constanza Contreras', 'Cristian Godoy', 'Laura Román'];
//     let listMail = ['ccontrerasu@transelec.cl', 'cgodoy@transelec.cl', 'lroman@transelec.cl'];
//     let i = 0;

//     var globalConf = firebase.database().ref(db_ref_inventario);
//     globalConf.once("value", (snapshot) => {
//         snapshot.forEach((childSnapshot) => {
//             console.log("i: %s - childSnapshot.key: %s - (%s,%s)", i, childSnapshot.key, list[i], listMail[i]);
//             globalConf.child(childSnapshot.key).update({'ingenieroContrato': list[i], 'mailIngenieroContrato': listMail[i]});
//             i = (i + 1) % 3;
//         });
//     })
// }

export function getSolicitudes(db_ref_solicitudes, callback) {
    console.log("<·><><·> getSolicitudes() <·><><·>");
    var globalConf = firebase.database().ref(db_ref_solicitudes);
    globalConf.orderByChild("fecha").on("value", (snapshot) => {
        let list = [];
        let i = 0;
        snapshot.forEach((childSnapshot) => {
            list.push({ key: childSnapshot.key, ...childSnapshot.val() });
        });
        // this.fullTable.forEach(x => { console.log(" --- " + JSON.stringify(x))});
        callback(list);
    });
}

export function getUsers(db_ref_users, callback) {
    console.log("<·><><·> getUsers() <·><><·>");
    var globalConf = firebase.database().ref(db_ref_users); 
    globalConf.on("value", (snapshot) => {
        let list = [];
        let i = 0;    
        snapshot.forEach((childSnapshot) => {
            // console.log(" >>>>>> " + JSON.stringify(ArrayDB));
            list.push({ key: childSnapshot.key, itemN: ++i, fullName: childSnapshot.val().nombres + ' ' + childSnapshot.val().apellidos,...childSnapshot.val() });
        });
        // this.fullTable.forEach(x => { console.log(" --- " + JSON.stringify(x))});
        callback(list);
    });
}

export function getUserKeyAndType(db_ref_users, useremail, callbackSuccess) {
    console.log("getUserKeyAndType");
    const dbRef = firebase.database().ref(db_ref_users);
    dbRef.orderByChild("user").equalTo(useremail).once("value", (snapshot) => {
        snapshot.forEach((childSnapshot) => {
            try{
                callbackSuccess(childSnapshot.key, childSnapshot.val().type, childSnapshot.val());
            }
            catch(e)
            {
                console.log("error en callbackSuccess %s", e);
            }
        });
    });
}


//Utils
export function stringInject(str, obj) {
// const stringInject = (str = '', obj = {}) => {
    let newStr = str;
    Object.keys(obj).forEach((key) => {
      let placeHolder = `#${key}#`;
      if(newStr.includes(placeHolder)) {
        newStr = newStr.replace(placeHolder, obj[key] || " ");
      }
    });
    return newStr;
  }