import moment from "moment";

export function downloadFileFromURL(url, filename) {
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  if(!xhr.onload)
  {
    xhr.onload = function(event) {
      console.log('xhr.onload-------' + event);
      let blob = xhr.response;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
      console.log('xhr.onload out-------');
      };
  }

  xhr.open('GET', url);
  xhr.send();
  console.log('downloadFileFromURL out--------');
}

export function daysToDate(value) {
  return Math.ceil((new Date(value).getTime() - Date.now())/ (1000 * 60 * 60 * 24));
}

//Formatos
export function formatoCLP(value) {
  // let val = (value/1).toFixed(2).replace('.', ',');
  if (value == null) return "";
  return "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function formatDate(datestring, dateFormat) {
  if(datestring == null || datestring == '') return '';
  return moment(datestring).format(dateFormat); //'DD-MM-YYYY'
}

// RUT
export function validRut(rut) {
  return rutRegex(rut) && rutValidation(rut);
}

export function rutRegex(rut) {
  if (rut) {
    let rutRegEx = /(?:\d{0,2}\.?)?(?:\d{3}\.?)?\d{1,3}(?:-?[kK\d]?)?/;
    return (rut.match(rutRegEx) == rut.trim())
  } else return true;
}

export function rutValidation(rut) {
  // src: https://cesarg.cl/validador-de-rut-chileno-con-javascript/
  if (!rut) return true;
  let verificador = rut.charAt(rut.length-1),
    counter = 2,
    validacion = 0;
  for (let ind = rut.length-2; ind >=0; ind--) {
    let c = rut.charAt(ind);
    if (isNaN(c)) continue;
    else {
      validacion += counter * parseInt(c);
      if (counter < 7) counter++;
      else counter = 2
    }
  }
  validacion = 11 - (validacion % 11);
  if (validacion == 11 && (verificador == "0" || verificador == 0)) return true;
  else if (validacion == 10 && (verificador == "k" || verificador == "K")) return true;
  else if (validacion == parseInt(verificador)) return true;
  else return false;
}
  