<template>
  
    
    <div>
      <FileUpload
        name="fileList[]"
        :customUpload="true"
        chooseLabel="Seleccionar Archivo"
        uploadLabel="Cargar Archivo"
        cancelLabel="Cancelar"
        :fileLimit="1"
        :maxFileSize="20000000"
        invalidFileSizeMessage="{0}: Tamaño de archivo inválido, el tamaño máximo permitido es {1}."
        invalidFileLimitMessage="Se supera cantidad máxima permitida de archivos, el límite es {0} máximo."
        @uploader="handleFileUpload"
      >
        <template #empty>
          <p>
            Seleccione archivo o arrastre directamente aquí los archivos que
            desea cargar.
          </p>
        </template>
      </FileUpload>
      <div v-if="habilitarComentarios">
        <h5>Comentarios:</h5>
        <Textarea v-model="comentarioUsuario" rows="2" cols="30" />
      </div>
    </div>
    <div v-if="showProgressBar">
      <base-progress :value="uploadProgress" striped="true" showPercentage="true" />
    </div>
    <div v-if="uploadProgress == 100">
      <Message severity="success" :closable="true" @close="$emit('close')">Carga exitosa</Message>
    </div>

    <!--
    <template #footer>
      <base-button type="primary" icon="ni ni-fat-add" @click="$emit('close')" class="p-button-text"/>
      <base-button type="primary"  icon="ni ni-fat-remove" @click="$emit('close')" autofocus />
    </template>
    -->
  
</template>

<script>
import firebase from "firebase";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import Message from "primevue/message";
import RadioButton from "primevue/radiobutton";
import Textarea from "primevue/textarea";

export default {
  name: "modal-carga-archivos",
  components: {
    FileUpload,
    Message,
    RadioButton,
    Dialog,
    Dropdown,
    Textarea,
  },
  props: {
    categoria: {
      type: String,
      
    },
    type: {
      type: String,
    },
    title: String,
    TIPO_CARGA: String,
    showModal: {
      type: Boolean,
      default: false,
    },
    tagOrigen:{
      type: String,
      default: "",
    },
    razonSocial:{
      type: String,
      default: "",
    },
    periodo:{
      type: String,
      default: "",
    },
    cargakey:{
      type: String,
      default: "",
    },
    tipoDocumentos: {
      type: Array,
      default: function () {
        return [];
      },
    },
    modoActualizacion: {
      type: Boolean,
      default: false,
    },
    habilitarComentarios: {
      type: Boolean,
      default: false,
    },
    excelProcess: {
      type: Boolean,
      default: false,
    },
    idEquipo: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      show: this.showModal,
      showProgressBar: false,
      uploadProgress: 0,
      timestampLastAction: 0,
      timestampLastProcess: 0,
      carpetaDestino: "/",
      
      //forms
      comentarioUsuario: "",
      dropdownTipoDocumento: "",
      dropdownSubcontrato: "",
      rbtTipoContrato: "principal",
      NC: [],
      excelFile: null,
    };
  },
  watch: {
    showModal: function()
    {
      console.log("showModal --- on change event --- show:"+this.showModal );
      if(this.showModal)
      {
        //se resetea formulario
        this.comentarioUsuario = "";
        this.dropdownTipoDocumento = "";
        this.dropdownSubcontrato = "";
        this.showProgressBar = false;
        this.uploadProgress = 0;
        this.rbtTipoContrato = "principal";

        this.show = true; //show
      }
      else
      {
        if(this.show) this.show = false;
      }
    }
  },
  methods: {

    isDuplicatedCall() {
      //ToDo: resolver tema de llamada doble
      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastAction));
      if (timestamp - this.timestampLastAction > 2000) {
        this.timestampLastAction = timestamp;
        return false;
      } else {
        console.log("----- double call exit -----");
        return true;
      }
    },

    handleFileUpload(event) {
      console.log("handleFileUpload--------");
      this.showProgressBar = true;
      this.submitFile(event.files);
    },

    submitFile: function (files) {
      console.log("submitFile-------");
      // let IDCarga = DateTime.Now();
      let IDCarga = Date.now();
      this.carpetaDestino += "/" + IDCarga;

      // Create a root reference
      var storageRef = firebase.storage().ref().child("REPORTES");
      var folderRef = storageRef.child(this.carpetaDestino);

      // Create a reference to file name
      var fileRef = folderRef.child(files[0].name);

      // var metadata = {
      //   //contentType: 'image/jpeg',
      //   owner: "GC-Hub",
      // };

      let gl = this;

      // Upload the file and metadata
      //var uploadTask = fileRef.put(files[0], metadata); //ORIGINAL
      var uploadTask = fileRef.put(files[0]);
      this.excelFile = files[0]; // used for Excel
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          gl.uploadProgress =
            Math.trunc((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log("Upload is " + gl.uploadProgress + "% done");

          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
            default:
              console.log(snapshot.state);
              break;
          }
        },
        function (error) {
          // Handle unsuccessful uploads

          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;

            case "storage/canceled":
              // User canceled the upload
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
          console.log("upload error: " + error.code);
        },
        () => {
          //successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log("File available at", downloadURL);
            //Insert in db reference
            const carga = {
              NombreArchivo: uploadTask.snapshot.ref.name,
              url: {url: downloadURL, name: uploadTask.snapshot.ref.name},
              FechaCarga: IDCarga,
              categoria: this.categoria,
              id: this.idEquipo
            }
            this.insertarArchivo(carga);
          });
        }
      );
    },
    async insertarArchivo (carga){
      console.log("entra a insertarArchivo a firebase")
      console.log(carga);
      await firebase.database().ref("/cargas").push(carga); //ToDo: "/cargas" -> "/cargas/BHP"
      
    },
    pushCargasDB: function (
      numContrato,
      tipoContrato,
      razonSocial,
      txtPeriodo,
      comentarioUsuario,
      fileDataArray,
      IDCarga
    ) {
      try {
        if (this.isDuplicatedCall()) return;

        const postListRef = firebase.database().ref("cargas/" + numContrato);
        // Si es actualización, se debe dejar como eliminada la carga anterior
        if (this.modoActualizacion) {
          postListRef
            .orderByChild("tipoRecepcion")
            .equalTo(this.TIPO_CARGA)
            .once("value", (snapshotInfo) => {
              snapshotInfo.forEach((childSnapshotInfo) => {
                if (
                  childSnapshotInfo.val().estado !== "DELETED" &&
                  childSnapshotInfo.val().descarga.length > 0
                ) {
                  if (
                    childSnapshotInfo.val().descarga[0].categoria ===
                    fileDataArray[0].categoria
                  ) {
                    const pathCarga =
                      "cargas/" + numContrato + "/" + childSnapshotInfo.key;
                    console.log("pathCarga (modoActualizacion): " + pathCarga);
                    firebase
                      .database()
                      .ref(pathCarga)
                      .update({ estado: "DELETED" });
                  }
                }
              });
            });
        }

        const pathNuevaCarga = "cargas/" + numContrato + "/" + IDCarga;

        firebase
          .database()
          .ref(pathNuevaCarga)
          .set(
            {
              // postListRef.child(IDCarga).set({
              estado: "PEND_VERIF",
              fechaCarga: Date.now(), //epoch timestamp
              numDocs: fileDataArray.length, //2,
              periodo: txtPeriodo, //"MAY 2021",
              razonSocial: razonSocial, //"SERV MINARDI", //Se tiene id de empresa, pero no su nombre
              tipoContrato: tipoContrato, //"SUBCONTRATO",
              tipoRecepcion: this.TIPO_CARGA, //"Control Laboral", "Carga Info Empresa", etc
              comentarioUsuario: comentarioUsuario,
              descarga: fileDataArray, //"linkx"
            },
            (error) => {
              if (error) {
                console.log("ERROR en pushCargasDB:" + error);
              } else {
                // Carga OK
                this.$emit("onCargaExitosa", {
                  pathNuevaCarga: pathNuevaCarga,
                  tagOrigen: this.tagOrigen,
                }); //para recuperar ID
              }
            }
          );
      } catch (e) {
        console.log("ERROR: WRITE DB");
        console.log(e);
      }
    },

  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.colored {
  background-color: #ff0000;
  color: #ffffff;
}
</style>
