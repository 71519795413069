import firebase from "firebase";
import "firebase/database"; 

export function getRTULastData(deviceID) {

    return new Promise((resolve, reject) => {
        try {
            firebase.database().ref("TrackAndTrace/RTUData/realtime/" + deviceID).once('value', (snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    resolve(response);
                }
                else {
                    resolve(false);
                }
            });
        } catch (error) {
            reject(error);
        }
    });
};
