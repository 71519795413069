<template>
  <stats-card
    :title="title"
    type="gradient-green"
    :sub-title="subtitle"
    icon="ni ni-active-40"
    class="mb-4 mb-xl-3 w-100"
  >
    <template v-slot:footer>
      <div id="chart" class="mb-5">
        <apexchart type="line" height="350" :options="chartOptionsTempDay" :series="seriesTempDay"></apexchart>
      </div>
<!-- 
      <div id="chart" class="mb-5">
        <apexchart type="line" height="350" :options="chartOptionsTempWeek" :series="seriesTempWeek"></apexchart>
      </div>
      <div id="chart" class="mb-5">
        <apexchart type="line" height="350" :options="chartOptionsTempMonth" :series="seriesTempMonth"></apexchart>
      </div>
 -->
    </template>
  </stats-card>
</template>
<script>

import VueApexCharts from "vue3-apexcharts";
import { getDeviceData } from "@/model/functions_powerline-ms";

export default {
  name: "powerlinems-temperatura-equipos",

  components: {
    apexchart: VueApexCharts,
  },

  props: {
    title: {
      type: String,
      default: "Temperatura equipo",
    },
    subtitle: {
      type: String,
      default: "",
    },
    
    deviceID: {
      type: String,
      default: "",
    },

    series: {
      type: Array,
      default: [
        {
          name: "High - 2013+",
          data: [28, 29, 33, 36, 32, 32, 33]
        },
        {
          name: "Low - 2013",
          data: [12, 11, 14, 18, 17, 13, 13]
        }
      ],
    },    
  },

  data() {
    return {

      // seriesAltura: [],
      seriesTemp: [],

      seriesTempDay: [],
      seriesTempWeek: [],
      seriesTempMonth: [],

      chartOptionsTemp: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454', '#345474'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: this.chartTitle,
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Horario'
          }
          // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
          // title: {
          //   text: 'Month'
          // }
        },
        yaxis: {
          title: {
            text: 'Temperatura'
          },
          // min: 5,
          // max: 40
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },

      chartOptionsTempDay: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454', '#345474'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: "Temperatura últimas 24 horas",
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Horario'
          }
        },
        yaxis: {
          title: {
            text: 'Temperatura'
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },

      chartOptionsTempWeek: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454', '#345474'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: "Temperatura últimos 7 días",
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Fecha'
          }
        },
        yaxis: {
          title: {
            text: 'Temperatura'
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },

      chartOptionsTempMonth: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454', '#345474'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: "Temperatura últimos 30 días",
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Fecha'
          }
        },
        yaxis: {
          title: {
            text: 'Temperatura'
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },

    };
  },

  methods: {},

  created() { //when the website mounts...starts

    getDeviceData(this.db_ref_stuMessages, this.deviceID, (datos) => {
      // console.log("datos -->", datos);
      const seriesTemperature = [
        {
          name: "Temperatura Conductor",
          data: []
        },
        {
          name: "Temperatura IMU",
          data: []
        },
        {
          name: "Temperatura Ambiente",
          data: []
        },
      ]

      this.seriesTemp = JSON.parse(JSON.stringify(seriesTemperature));
      this.seriesTempDay = JSON.parse(JSON.stringify(seriesTemperature));
      this.seriesTempWeek = JSON.parse(JSON.stringify(seriesTemperature));
      this.seriesTempMonth = JSON.parse(JSON.stringify(seriesTemperature));

      // this.seriesAltura = [
      //   {
      //     name: "Altura Actual",
      //     data: []
      //   },
      //   {
      //     name: "Altura Anterior",
      //     data: []
      //   },
      // ]

      const utc_offset = 3*3600000;

      const offset_day = 24*3600000;
      const offset_week = offset_day * 7;
      const offset_month = offset_day * 30;

      this.seriesTempDay[0].data.push([new Date(new Date().getTime() - utc_offset - offset_day), null]);
      this.seriesTempDay[0].data.push([new Date(new Date().getTime() - utc_offset), null]);
      this.seriesTempDay[1].data.push([new Date(new Date().getTime() - utc_offset - offset_day), null]);
      this.seriesTempDay[1].data.push([new Date(new Date().getTime() - utc_offset), null]);
      this.seriesTempDay[2].data.push([new Date(new Date().getTime() - utc_offset - offset_day), null]);
      this.seriesTempDay[2].data.push([new Date(new Date().getTime() - utc_offset), null]);

      this.seriesTempWeek[0].data.push([new Date(new Date().getTime() - utc_offset), null]);
      this.seriesTempWeek[1].data.push([new Date(new Date().getTime() - utc_offset), null]);
      this.seriesTempWeek[2].data.push([new Date(new Date().getTime() - utc_offset), null]);

      this.seriesTempMonth[0].data.push([new Date(new Date().getTime() - utc_offset), null]);
      this.seriesTempMonth[1].data.push([new Date(new Date().getTime() - utc_offset), null]);
      this.seriesTempMonth[2].data.push([new Date(new Date().getTime() - utc_offset), null]);

      datos.forEach(element => {
        // console.log("element -->", element);

        // if ((new Date.now().getTime()) < element.timestamp && element.timestamp < (new Date('21 Nov 2023 GMT').getTime()))
        // if ((new Date('15 Jan 2024 GMT').getTime()) < element.timestamp && element.timestamp < (new Date('21 Nov 2024 GMT').getTime()))
        if ((new Date().getTime() - offset_day) < element.timestamp)
        {
          // Temperatura
          if ((element.data.temperaturaCamara * 10) != 0x7FFF && (element.data.temperaturaCamara * 10) > -15)
            this.seriesTempDay[0].data.push([new Date(element.timestamp - utc_offset), element.data.temperaturaCamara]);

          if ((element.data.temperaturaIMU * 10) != 0x7FFF)
            this.seriesTempDay[1].data.push([new Date(element.timestamp - utc_offset), element.data.temperaturaIMU]);

          if ((element.data.temperaturaSE * 10) != 0x7FFF && this.deviceID != '0-4739095')
            this.seriesTempDay[2].data.push([new Date(element.timestamp - utc_offset), element.data.temperaturaSE]);

          // // Altura
          // if ((element.data.alturaActual * 10) != 0xFFFF)
          //   this.seriesAltura[0].data.push([new Date(element.timestamp - utc_offset), element.data.alturaActual]);
          // if ((element.data.alturaAnterior * 10) != 0xFFFF)
          //   this.seriesAltura[1].data.push([new Date(element.timestamp - utc_offset), element.data.alturaAnterior]);
        }

        if ((new Date().getTime() - offset_week) < element.timestamp)
        {
          // Temperatura
          if ((element.data.temperaturaCamara * 10) != 0x7FFF && (element.data.temperaturaCamara * 10) > -15)
            this.seriesTempWeek[0].data.push([new Date(element.timestamp - utc_offset), element.data.temperaturaCamara]);

          if ((element.data.temperaturaIMU * 10) != 0x7FFF)
            this.seriesTempWeek[1].data.push([new Date(element.timestamp - utc_offset), element.data.temperaturaIMU]);

          if ((element.data.temperaturaSE * 10) != 0x7FFF && this.deviceID != '0-4739095')
            this.seriesTempWeek[2].data.push([new Date(element.timestamp - utc_offset), element.data.temperaturaSE]);

          // // Altura
          // if ((element.data.alturaActual * 10) != 0xFFFF)
          //   this.seriesAltura[0].data.push([new Date(element.timestamp - utc_offset), element.data.alturaActual]);
          // if ((element.data.alturaAnterior * 10) != 0xFFFF)
          //   this.seriesAltura[1].data.push([new Date(element.timestamp - utc_offset), element.data.alturaAnterior]);
        }
        
        if ((new Date().getTime() - offset_month) < element.timestamp)
        {
          // Temperatura
          if ((element.data.temperaturaCamara * 10) != 0x7FFF && (element.data.temperaturaCamara * 10) > -15)
            this.seriesTempMonth[0].data.push([new Date(element.timestamp - utc_offset), element.data.temperaturaCamara]);

          if ((element.data.temperaturaIMU * 10) != 0x7FFF)
            this.seriesTempMonth[1].data.push([new Date(element.timestamp - utc_offset), element.data.temperaturaIMU]);

          if ((element.data.temperaturaSE * 10) != 0x7FFF && this.deviceID != '0-4739095')
            this.seriesTempMonth[2].data.push([new Date(element.timestamp - utc_offset), element.data.temperaturaSE]);

          // // Altura
          // if ((element.data.alturaActual * 10) != 0xFFFF)
          //   this.seriesAltura[0].data.push([new Date(element.timestamp - utc_offset), element.data.alturaActual]);
          // if ((element.data.alturaAnterior * 10) != 0xFFFF)
          //   this.seriesAltura[1].data.push([new Date(element.timestamp - utc_offset), element.data.alturaAnterior]);
        }
        
      });
    });    
  }

};
</script>
<style></style>
