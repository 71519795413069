<template>
  <div>
<!--
    <base-header
      style="background-size: 100% 500px;background-image:linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.2)), url('img/aisladores.jpg');"     
      class="pb-6 pb-8 pt-5 pt-md-8">
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header>
 -->
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      :style="(this.headers && this.headers.detalleEquipo)? {
        maxHeight: '150px',
        backgroundImage: this.headers.detalleEquipo.backgroundImage,
        backgroundSize: 'cover',
        backgroundPosition: this.headers.detalleEquipo.backgroundPosition
      } : { maxHeight: '150px' }"
    >
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header>

    <Toast position="bottom-right"/>
    <Dialog
      header="Reportar falla equipo"
      v-model:visible="showModalReportarFalla"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"
    >
      <div class="container">
        <div class="row my-3">
          <b class="my-auto">Describa la falla o defecto a reportar del equipo:</b>
        </div>
        <div class="row my-3">
          <div class="col-12">
            <div class="p-inputgroup">
              <!-- <span class="p-inputgroup-addon"><i class="pi pi-comments" /></span> -->
              <Textarea
                v-model="observacion"
                class="w-100"
              />
              </div>
          </div>
        </div>
        <Button label="Generar solicitud ejecución garantía" class="p-button-raised p-button-default" @click="reportarFalla(observacion)"/>
      </div>
    </Dialog>

    <Dialog
      header="Agregar Comentario"
      v-model:visible="showModalAgregarComentario"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"
    >
      <div class="container">
        <div class="row my-3">
          <b class="my-auto">Ingrese comentario respecto al equipo:</b>
        </div>
        <div class="row my-3">
          <div class="col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-comments" /></span>
              <Textarea
                v-model="observacion"
                class="fullwidth"
              />
            </div>
          </div>
        </div>
        <Button label="Agregar Comentario" class="p-button-raised p-button-default" @click="agregarComentario(observacion, 'INFO')"/>
      </div>
    </Dialog>

    <Dialog
    header="Subir Garantías"
    v-model:visible="showModalUploadFiles"
    :breakpoints="{'960px': '90vw', '640px': '100vw'} " :style="{width: '75vw'}">
    <ModalCargaArchivos categoria="garantia" :idEquipo="this.elementid"/>
    </Dialog>

    <Dialog
    header="Subir Fotos de Equipo"
    v-model:visible="showModalUploadPhotos"
    :breakpoints="{'960px': '90vw', '640px': '100vw'} " :style="{width: '75vw'}">
    <ModalCargaArchivos categoria="fotoEquipo" :idEquipo="this.elementid"/>
    </Dialog>

    <Dialog
    header="Subir Manuales Técnicos"
    v-model:visible="showModalUploadManuales"
    :breakpoints="{'960px': '90vw', '640px': '100vw'} " :style="{width: '75vw'}">
    <ModalCargaArchivos categoria="manuales" :idEquipo="this.elementid"/>
    </Dialog>
    <Dialog
    header="Subir Documentos Administrativos"
    v-model:visible="showModalUploadDocs"
    :breakpoints="{'960px': '90vw', '640px': '100vw'} " :style="{width: '75vw'}">
    <ModalCargaArchivos categoria="docs" :idEquipo="this.elementid"/>
    </Dialog>

    <Dialog
      header="Archivos Adjuntados"
      v-model:visible="showModalDetalleArchivos"
      :breakpoints="{'960px': '90vw', '640px': '100vw'}" :style="{width: '75vw'}"
    >
      <DataTable
        class="p-datatable-customers"
        :value="listUploadedFiles"
        :paginator="true"
        :row-selectable="true"
        :rows="10"
        :rowsPerPageOptions="[10,25,50]"
        responsiveLayout="scroll"
        rowStyleClass="#{listUploadedFiles.Evaluacion le 90 ? 'colored' : null}"
      >
        <Column field="NombreArchivo" header="Archivo"></Column>
        <Column field="categoria" header="Tipo Documento"></Column>
        <Column field="FechaCarga" header="Fecha Carga">
          <template #body="body">
            {{ formatDate(body.data.FechaCarga, 'DD-MM-YYYY') }}
          </template>
        </Column>
        <Column  header="Descarga">
        <template #body="body" >
            <base-button
              v-model="url"
              icon="pi pi-cloud-download"
              size="sm"
              type="secondary"              
              v-on:click="downloadFile(body, $event)"
            />
          </template>
        </Column>
      </DataTable>
    </Dialog>

    <Dialog
      header="Datos Equipo"
      v-model:visible="showDetalleTemperatura"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '75vw'}"
    >
      <div class="container">
        <div class="row my-3">
          <div class="col-xl-12 col-lg-4">
            <div class="row mr-3" >
              <grafico-temperatura
                title="Temperatura equipo" :subtitle="datosEquipo.TAG ?? ''" :series="seriesTemp" :deviceID="this.datosEquipo.ID"/>
            </div>
        </div>
        </div>
      </div>
    </Dialog>

    <Dialog
      header="Datos Equipo"
      v-model:visible="showDetalleAltura"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '75vw'}"
    >
      <div class="container">
        <div class="row my-3">
          <div class="col-xl-12 col-lg-4">
            <div class="row mr-3" >
              <grafico-altura
                title="Altura equipo" :subtitle="datosEquipo.TAG ?? ''" :series="seriesAltura" :deviceID="this.datosEquipo.ID"/>
            </div>
        </div>
        </div>
      </div>
    </Dialog>

    <Dialog
      header="Datos Equipo"
      v-model:visible="showDetalleAcelerometro"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '75vw'}"
    >
      <div class="container">
        <div class="row my-3">
          <div class="col-xl-12 col-lg-4">
            <div class="row mr-3" >
              <grafico-aceleraciones
                title="Acelerometro equipo" :subtitle="datosEquipo.TAG ?? ''" :deviceID="this.datosEquipo.ID"/>
            </div>
        </div>
        </div>
      </div>
    </Dialog>

    <Dialog
      header="Datos Equipo"
      v-model:visible="showDetalleGiroscopio"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '75vw'}"
    >
      <div class="container">
        <div class="row my-3">
          <div class="col-xl-12 col-lg-4">
            <div class="row mr-3" >
              <grafico-giroscopio
                title="Giroscopio equipo" :subtitle="datosEquipo.TAG ?? ''" :deviceID="this.datosEquipo.ID"/>
            </div>
        </div>
        </div>
      </div>
    </Dialog>
    
    <div v-if="loading == false" class="container-fluid mt--7">
      <div v-if="notFound" class="row" >
        <div class="col-xl-7 col-lg-4">
          <div class="row w-100" >
            <div class="colum w-100">
              <stats-card
                title="Nombre del Equipo"
                type="gradient-orange"
                sub-title="Equipo no encontrado"
                icon="ni ni-notification-70"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <br/><br/><br/><br/>
                </template>
              </stats-card>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="row" >
        <!-- Tarjetas Columna Central -->
        <div class="col-xl-7 col-lg-4">

          <!-- "Número de serie" -->
          <!-- "Nombre del Equipo" -->
          <!-- "Descripción del Equipo" -->
          <!-- "Fabricante" -->
          <!-- "Proveedor" -->
          <!-- "Modelo Equipo" -->
          <!-- "Ubicación" -->
          <!-- "Área Responsable" -->
          <!-- "Comentarios (tension kV)" -->
          <!-- "Identificador contrato" -->
          <!-- "Año fabricacion" -->
          <!-- "Fecha de PES" -->
          <!-- "Meses" -->
          <!-- "Fecha garantía hasta" -->
          <!-- "Expiración Garantía" -->
          <!-- "Fotos Equipo" -->
          <!-- "Manuales Técnicos" -->
          <!-- "Documentos administrativos" -->
          <!-- "Categoría Equipo" -->
          <!-- "Criticidad" -->

          <!-- Tarjeta Equipo -->
          <div class="row w-100" >
            <div class="colum w-100">
              <stats-card
                title="Nombre del Equipo"
                type="gradient-orange"
                v-bind:sub-title="datosEquipo.nombreEquipo"
                icon="ni ni-bold-left"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>    
                  <Message v-if="datosEquipo.falla" severity="error" :closable="false">Equipo reportado en falla el {{ formatDate(datosEquipo.fechaFalla, 'DD-MM-YYYY') }}</Message>
                  <div class="row">
                    <div class="col-auto">
                      <table v-if="site == 'Powerline MS' || site == 'Fepasa' ">
                        <tbody>
                          <tr><td style="padding-right:10px"><b>TAG Equipo</b></td>{{datosEquipo.TAG ?? "Sin informacion" }}<td></td></tr>
                          <tr><td style="padding-right:10px"><b>ID equipo</b></td><td>{{datosEquipo.ID}}</td></tr>

                          <tr v-if="datosEquipo.ubicacion"><td style="padding-right:10px"><b>Ubicación</b></td><td>{{datosEquipo.ubicacion}}</td></tr>
                          <tr v-if="datosEquipo.areaResponsable"><td style="padding-right:10px"><b>Área Responsable</b></td><td>{{datosEquipo.areaResponsable}}</td></tr>
                          <tr v-if="datosEquipo.ADC"><td style="padding-right:10px"><b>ADC</b></td><td>{{datosEquipo.ADC}}</td></tr>

                          <tr v-if="datosEquipo.fechaPES"><td style="padding-right:10px"><b>Fecha de PES</b></td><td>{{datosEquipo.fechaPES}}</td></tr>
                          <tr v-if="datosEquipo.categoria"><td style="padding-right:10px"><b>Categoría Equipo </b></td><td>{{datosEquipo.categoria}}</td></tr>
                          <tr v-if="datosEquipo.criticidad"><td style="padding-right:10px"><b>Criticidad</b></td><td>{{datosEquipo.criticidad}}</td></tr>
                        </tbody>
                      </table>
                      <table v-else-if="(site == 'BHP' || site == 'Demo' || site == 'Aquanima-Codelco' || site == 'Celplan-Codelco')">
                        <tbody>
                          <tr><td style="padding-right:10px"><b>TAG Equipo</b></td>{{datosEquipo.TAG ?? "Sin informacion" }}<td></td></tr>

                          <tr><td style="padding-right:10px"><b>Contrato</b></td><td>{{datosEquipo.contrato}}</td></tr>
                          <tr><td style="padding-right:10px"><b>Nº Contrato</b></td><td>{{datosEquipo.idContrato}}</td></tr>

                          <tr v-if="datosEquipo.ubicacion"><td style="padding-right:10px"><b>Ubicación</b></td><td>{{datosEquipo.ubicacion}}</td></tr>
                          <tr v-if="datosEquipo.plano"><td style="padding-right:10px"><b>Plano</b></td><td>{{datosEquipo.plano}}</td></tr>
                          <tr v-if="datosEquipo.areaResponsable"><td style="padding-right:10px"><b>Área Responsable</b></td><td>{{datosEquipo.areaResponsable}}</td></tr>
                          <tr v-if="datosEquipo.ADC"><td style="padding-right:10px"><b>ADC</b></td><td>{{datosEquipo.ADC}}</td></tr>
                          <tr v-if="datosEquipo.fechaPES"><td style="padding-right:10px"><b>Fecha de PES</b></td><td>{{datosEquipo.fechaPES}}</td></tr>
                          <tr v-if="datosEquipo.categoria"><td style="padding-right:10px"><b>Categoría Equipo </b></td><td>{{datosEquipo.categoria}}</td></tr>
                          <tr v-if="datosEquipo.criticidad"><td style="padding-right:10px"><b>Criticidad</b></td><td>{{datosEquipo.criticidad}}</td></tr>
                        </tbody>
                      </table>
                      <table v-else>
                        <tbody>
                          <!-- <tr><td style="padding-right:10px"><b>Identificador {{ this.clientName }}</b></td><td>{{datosEquipo.ID}}</td></tr> -->
                          <!-- <tr><td style="padding-right:10px"><b>Descriptor Equipo / Codigo SAP</b></td>{{datosEquipo.TAG}}<td></td></tr> -->
                          <tr><td style="padding-right:10px"><b>TAG Equipo</b></td>{{datosEquipo.TAG ?? "Sin informacion" }}<td></td></tr>
                          <tr><td style="padding-right:10px"><b>Contrato</b></td><td>{{datosEquipo.contrato}}</td></tr>
                          <tr><td style="padding-right:10px"><b>Nº Contrato</b></td><td>{{datosEquipo.idContrato}}</td></tr>
                          <!-- <tr><td style="padding-right:10px"><b>Identificador contrato</b></td><td>{{datosEquipo.idContrato}}</td></tr> -->

                          <!-- <tr><td style="padding-right:10px"><b>Número de serie</b></td><td>{{datosEquipo.numeroSerie}}</td></tr> -->
                          <!-- <tr><td style="padding-right:10px"><b>Nombre del Equipo</b></td><td>{{datosEquipo.nombreEquipo}}</td></tr> -->

                          <!-- <tr><td style="padding-right:10px"><b>Descripción del Equipo</b></td><td>{{datosEquipo.descripcion}}</td></tr> -->

                          <!-- <tr><td style="padding-right:10px"><b>Fabricante</b></td><td>{{datosEquipo.fabricante}}</td></tr> -->
                          <!-- <tr><td style="padding-right:10px"><b>Proveedor</b></td><td>{{datosEquipo.proveedor}}</td></tr> -->
                          <!-- <tr><td style="padding-right:10px"><b>Modelo Equipo</b></td><td>{{datosEquipo.modelo}}</td></tr> -->
                          <tr v-if="datosEquipo.ubicacion"><td style="padding-right:10px"><b>Ubicación</b></td><td>{{datosEquipo.ubicacion}}</td></tr>
                          <tr v-if="datosEquipo.areaResponsable"><td style="padding-right:10px"><b>Área Responsable</b></td><td>{{datosEquipo.areaResponsable}}</td></tr>
                          <tr v-if="datosEquipo.ADC"><td style="padding-right:10px"><b>ADC</b></td><td>{{datosEquipo.ADC}}</td></tr>

                          <!-- <tr><td style="padding-right:10px"><b>Comentarios (tension kV)</b></td><td>{{datosEquipo.comentarios}}</td></tr> -->
                          <!-- <tr><td style="padding-right:10px"><b>Año fabricacion</b></td><td>{{datosEquipo.anoFabricacion}}</td></tr> -->

                          <tr v-if="datosEquipo.fechaPES"><td style="padding-right:10px"><b>Fecha de PES</b></td><td>{{datosEquipo.fechaPES}}</td></tr>
                          <!-- <tr><td style="padding-right:10px"><b>Meses</b></td><td>{{datosEquipo.mesesGarantia}}</td></tr> -->
                          <!-- <tr><td style="padding-right:10px"><b>Fecha garantía hasta</b></td><td>{{ datosEquipo.fechaFinGarantia }}</td></tr> -->
                          <!-- <tr><td style="padding-right:10px"><b>Expiración Garantía</b></td><td>{{datosEquipo.expiracionGarantia}}</td></tr> -->

                          <!-- <tr><td style="padding-right:10px"><b>Fotos Equipo</b></td><td>{{datosEquipo.listImagenes}}</td></tr> -->
                          <!-- <tr><td style="padding-right:10px"><b>Manuales Técnicos</b></td><td>
                            <base-button
                              v-model="LinkArchivo"
                              icon="pi pi-paperclip"
                              size="sm"
                              type="secondary"
                              :id="JSON.stringify(datosEquipo.listAdjuntos)"
                              @click="showUploadFiles('', $event)"></base-button>
                          </td></tr> -->
                          <!-- <tr><td><b>Documentos administrativos</b></td><td>{{datosEquipo.listDocumentos}}</td></tr> -->
                          <tr v-if="datosEquipo.categoria"><td style="padding-right:10px"><b>Categoría Equipo </b></td><td>{{datosEquipo.categoria}}</td></tr>
                          <tr v-if="datosEquipo.criticidad"><td style="padding-right:10px"><b>Criticidad</b></td><td>{{datosEquipo.criticidad}}</td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </stats-card>
            </div>
          </div>

          <!-- Conectividad Equipo -->
          <div class="row w-100" >
            <div class="colum w-100">
              <stats-card
                v-if="this.sectionsElementView.infoOnlineEquipo"
                title="Estado Conectividad"
                type="gradient-orange"
                sub-title=""
                icon="ni ni-world-2"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <Message v-if="globalstarData.timestampLastMessage == 0"       
                        severity="info" :closable="false">Equipo no inicializado</Message>
                  <Message v-else-if="(Date.now() - globalstarData.timestampLastMessage) > this.timeAlerts.error"       
                        severity="error" :closable="false">Equipo sin conectivdad desde: {{ formatDate(globalstarData.timestampLastMessage, 'DD-MM-YYYY HH:mm') }} hrs</Message>
                  <Message v-else-if="(Date.now() - globalstarData.timestampLastMessage) > this.timeAlerts.warn"       
                        severity="warn" :closable="false">Equipo sin conectivdad desde: {{ formatDate(globalstarData.timestampLastMessage, 'DD-MM-YYYY HH:mm') }} hrs</Message>
                  <div class="row">
                    <div class="col-auto">
                      <table class="w-100">
                        <tbody>
                          <tr><td style="padding-right:10px"><b>Last STU Message</b></td><td v-if="globalstarData.stuMessage">{{ /* globalstarData.stuMessage.deliveryTimeStamp */ formatDate(globalstarData.stuMessage.timestamp, 'DD-MM-YYYY HH:mm') }}</td><td v-else>Sin información</td></tr>
                          <tr><td style="padding-right:10px"><b>Last Init Frame Message</b></td><td v-if="globalstarData.initFrameMessage">{{ /* globalstarData.initFrameMessage.deliveryTimeStamp */ formatDate(globalstarData.initFrameMessage.timestamp, 'DD-MM-YYYY HH:mm') }}</td><td v-else>Sin información</td></tr>
                          <tr><td style="padding-right:10px"><b>Last Health Message</b></td><td v-if="globalstarData.healthMessage">{{ /* globalstarData.healthMessage.deliveryTimeStamp */ formatDate(globalstarData.healthMessage.timestamp, 'DD-MM-YYYY HH:mm') }}</td><td v-else>Sin información</td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </stats-card>
            </div>
          </div>

          <!-- Ultimos Datos Equipo -->
          <div class="row w-100" >
            <div class="colum w-100">
              <stats-card
                v-if="this.sectionsElementView.infoOnlineEquipo"
                title="Datos Equipo"
                type="gradient-orange"
                sub-title=""
                icon="ni ni-bullet-list-67"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <Message v-if="(globalstarData.stuMessage && (Date.now() - globalstarData.stuMessage.timestamp > this.timeAlerts.error))"       
                            severity="error" :closable="false">Equipo sin datos desde: {{ formatDate(globalstarData.stuMessage.timestamp, 'DD-MM-YYYY HH:mm') }} hrs</Message>
                  <Message v-else-if="(globalstarData.stuMessage && (Date.now() - globalstarData.stuMessage.timestamp > this.timeAlerts.warn))"       
                            severity="warn" :closable="false">Equipo sin datos desde: {{ formatDate(globalstarData.stuMessage.timestamp, 'DD-MM-YYYY HH:mm') }} hrs</Message>
                  <div class="row">
                    <div class="col-xl-6 col-lg-4">
                      <table class="w-100">
                        <tbody>
                          <div class="w-100">
                            <tr v-if="globalstarData.stuMessage
                                    && globalstarData.stuMessage.data != null
                                    && globalstarData.stuMessage.data.alturaActual != null 
                                    && (globalstarData.stuMessage.data.alturaActual * 10) != 0xFFFF
                                    && (globalstarData.stuMessage.data.alturaActual * 10) != 0xBF20">
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-sound-wave" size="sm" type="primary" @click="showDetalleAltura = true"></base-button>
                                <b>Altura Actual</b>
                              </td>
                              <td>{{ (globalstarData.stuMessage.data.alturaActual / 100.0).toFixed(2) }} m</td>
                            </tr>
                            <tr v-else-if="globalstarData.stuMessageBefore 
                                        && globalstarData.stuMessageBefore.data != null 
                                        && globalstarData.stuMessageBefore.data.alturaActual != null 
                                        && (globalstarData.stuMessageBefore.data.alturaActual * 10) != 0xFFFF
                                        && (globalstarData.stuMessageBefore.data.alturaActual * 10) != 0xBF20">
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-sound-wave" size="sm" type="primary" @click="showDetalleAltura = true"></base-button>
                                <b>Altura Actual</b>
                              </td>
                              <td>{{ (globalstarData.stuMessageBefore.data.alturaActual / 100.0).toFixed(2) }} m - NLD</td>
                            </tr>
                            <tr v-else-if="globalstarData.ultimaAlturaValida 
                                        && (globalstarData.ultimaAlturaValida * 10) != 0xFFFF
                                        && (globalstarData.ultimaAlturaValida * 10) != 0xBF20">
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-sound-wave" size="sm" type="primary" @click="showDetalleAltura = true"></base-button>
                                <b>Altura Actual</b>
                              </td>
                              <td>{{ (globalstarData.ultimaAlturaValida / 100.0).toFixed(2) }} m - NLD</td>
                            </tr>
                            <tr v-else>
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-sound-wave" size="sm" type="primary" @click="showDetalleAltura = true"></base-button>
                                <b>Altura Actual</b>
                              </td><td>Sin información</td>                                
                            </tr>
                            <tr v-if="globalstarData.stuMessage
                                    && globalstarData.stuMessage.data != null 
                                    && globalstarData.stuMessage.data.alturaAnterior != null 
                                    && (globalstarData.stuMessage.data.alturaAnterior * 10) != 0xFFFF
                                    && (globalstarData.stuMessage.data.alturaAnterior * 10) != 0xBF20">
                              <td style="padding-right:10px">
                                <!-- <base-button icon="ni ni-sound-wave" size="sm" type="primary" @click="showDetalleAltura = true"></base-button> -->
                                <b>Altura Anterior</b>
                              </td>
                              <td>{{ (globalstarData.stuMessage.data.alturaAnterior / 100.0).toFixed(2) }} m</td>
                            </tr>
                            <tr v-else-if="globalstarData.stuMessageBefore
                                    && globalstarData.stuMessageBefore.data != null 
                                    && globalstarData.stuMessageBefore.data.alturaAnterior != null 
                                    && (globalstarData.stuMessageBefore.data.alturaAnterior * 10) != 0xFFFF
                                    && (globalstarData.stuMessageBefore.data.alturaAnterior * 10) != 0xBF20">
                              <td style="padding-right:10px">
                                <!-- <base-button icon="ni ni-sound-wave" size="sm" type="primary" @click="showDetalleAltura = true"></base-button> -->
                                <b>Altura Anterior</b>
                              </td>
                              <td>{{ (globalstarData.stuMessageBefore.data.alturaAnterior / 100.0).toFixed(2) }} m - NLD</td>
                            </tr>
                            <tr v-else-if="globalstarData.ultimaAlturaValida 
                                        && (globalstarData.ultimaAlturaValida * 10) != 0xFFFF
                                        && (globalstarData.ultimaAlturaValida * 10) != 0xBF20">
                              <td style="padding-right:10px">
                                <!-- <base-button icon="ni ni-sound-wave" size="sm" type="primary" @click="showDetalleAltura = true"></base-button> -->
                                <b>Altura Anterior</b>
                              </td>
                              <td>{{ (globalstarData.ultimaAlturaValida / 100.0).toFixed(2) }} m - NLD</td>
                            </tr>
                            <tr v-else>
                              <td style="padding-right:10px">
                                <!-- <base-button icon="ni ni-sound-wave" size="sm" type="primary" @click="showDetalleAltura = true"></base-button> -->
                                <b>Altura Anterior</b>
                              </td><td>Sin información</td> 
                            </tr>
                            <tr>
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-planet" size="sm" type="primary" @click="showDetalleAcelerometro = true"></base-button>
                                <b>Acelerometro (x,y,z)</b>
                              </td>
                              <td v-if="globalstarData.stuMessage
                                    && globalstarData.stuMessage.data != null 
                                    && globalstarData.stuMessage.data.acelerometroX != null 
                                    && globalstarData.stuMessage.data.acelerometroY != null 
                                    && globalstarData.stuMessage.data.acelerometroZ != null">
                                ({{globalstarData.stuMessage.data.acelerometroX}}, {{globalstarData.stuMessage.data.acelerometroY}}, {{globalstarData.stuMessage.data.acelerometroZ}})
                              </td>
                              <td v-else-if="globalstarData.stuMessageBefore
                                    && globalstarData.stuMessageBefore.data != null 
                                    && globalstarData.stuMessageBefore.data.acelerometroX != null 
                                    && globalstarData.stuMessageBefore.data.acelerometroY != null 
                                    && globalstarData.stuMessageBefore.data.acelerometroZ != null">
                                ({{globalstarData.stuMessageBefore.data.acelerometroX}}, {{globalstarData.stuMessageBefore.data.acelerometroY}}, {{globalstarData.stuMessageBefore.data.acelerometroZ}}) - NLD
                              </td>
                              <td v-else>Sin información</td>
                            </tr>
                            <tr>
                              <td style="padding-right:10px">
                                <!-- <base-button icon="ni ni-chart-pie-35" size="sm" type="primary" @click="showDetalleGiroscopio = true"></base-button> -->
                                <b>Giroscopio (vibracion ejes x,y,z)</b>
                              </td>
                              <td v-if="globalstarData.stuMessage
                                    && globalstarData.stuMessage.data != null 
                                    && globalstarData.stuMessage.data.giroscopioX != null 
                                    && globalstarData.stuMessage.data.giroscopioY != null 
                                    && globalstarData.stuMessage.data.giroscopioZ != null">
                                ({{globalstarData.stuMessage.data.giroscopioX}}, {{globalstarData.stuMessage.data.giroscopioY}}, {{globalstarData.stuMessage.data.giroscopioZ}})
                              </td>
                              <td v-else-if="globalstarData.stuMessageBefore
                                    && globalstarData.stuMessageBefore.data != null 
                                    && globalstarData.stuMessageBefore.data.giroscopioX != null 
                                    && globalstarData.stuMessageBefore.data.giroscopioY != null 
                                    && globalstarData.stuMessageBefore.data.giroscopioZ != null">
                                ({{globalstarData.stuMessageBefore.data.giroscopioX}}, {{globalstarData.stuMessageBefore.data.giroscopioY}}, {{globalstarData.stuMessageBefore.data.giroscopioZ}}) - NLD
                              </td>
                              <td v-else>Sin información</td>
                            </tr>
                            <tr>
                              <td style="padding-right:10px"><b>Latitud Actual</b></td>
                              <td v-if="globalstarData.stuMessage
                                    && globalstarData.stuMessage.data != null 
                                    && globalstarData.stuMessage.data.latitud != null 
                                    && globalstarData.stuMessage.data.latitud != 0">{{globalstarData.stuMessage.data.latitud}}</td>
                              <td v-else-if="globalstarData.stuMessageBefore
                                    && globalstarData.stuMessageBefore.data != null 
                                    && globalstarData.stuMessageBefore.data.latitud != null 
                                    && globalstarData.stuMessageBefore.data.latitud != 0">{{globalstarData.stuMessageBefore.data.latitud}} - NLD</td>
                              <td v-else>Sin información</td>
                            </tr>
                            <tr>
                              <td style="padding-right:10px"><b>Longitud Actual</b></td>
                              <td v-if="globalstarData.stuMessage
                                    && globalstarData.stuMessage.data != null 
                                    && globalstarData.stuMessage.data.longitud != null 
                                    && globalstarData.stuMessage.data.longitud != 0">{{globalstarData.stuMessage.data.longitud}}</td>
                              <td v-else-if="globalstarData.stuMessageBefore
                                    && globalstarData.stuMessageBefore.data != null 
                                    && globalstarData.stuMessageBefore.data.longitud != null 
                                    && globalstarData.stuMessageBefore.data.longitud != 0">{{globalstarData.stuMessageBefore.data.longitud}} - NLD</td>
                              <td v-else>Sin información</td>
                            </tr>
                            <tr v-if="globalstarData.stuMessage
                                    && globalstarData.stuMessage.data != null 
                                    && globalstarData.stuMessage.data.temperaturaCamara != null 
                                    && (globalstarData.stuMessage.data.temperaturaCamara * 10) > -15
                                    && (globalstarData.stuMessage.data.temperaturaCamara * 10) != 0x7FFF">
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-chart-bar-32" size="sm" type="primary" @click="showDetalleTemperatura = true"></base-button>
                                <b>Temperatura Conductor</b>
                              </td>
                              <td>{{globalstarData.stuMessage.data.temperaturaCamara}} ˚C</td>
                            </tr>
                            <tr v-else-if="globalstarData.stuMessageBefore
                                    && globalstarData.stuMessageBefore.data != null 
                                    && globalstarData.stuMessageBefore.data.temperaturaCamara != null 
                                    && (globalstarData.stuMessageBefore.data.temperaturaCamara * 10) > -15
                                    && (globalstarData.stuMessageBefore.data.temperaturaCamara * 10) != 0x7FFF">
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-chart-bar-32" size="sm" type="primary" @click="showDetalleTemperatura = true"></base-button>
                                <b>Temperatura Conductor</b>
                              </td>
                              <td>{{globalstarData.stuMessageBefore.data.temperaturaCamara}} ˚C - NLD</td>
                            </tr>
                            <tr v-else-if="globalstarData.ultimaTempCamaraValida 
                                        && (globalstarData.ultimaTempCamaraValida * 10) > -15
                                        && (globalstarData.ultimaTempCamaraValida * 10) != 0x7FFF">
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-chart-bar-32" size="sm" type="primary" @click="showDetalleTemperatura = true"></base-button>
                                <b>Temperatura Conductor</b>
                              </td>
                              <td>{{ (globalstarData.ultimaTempCamaraValida / 100.0).toFixed(2) }} ˚C - NLD</td>
                            </tr>
                            <tr v-else>
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-chart-bar-32" size="sm" type="primary" @click="showDetalleTemperatura = true"></base-button>
                                <b>Temperatura Conductor</b>
                              </td><td>Sin información</td>
                            </tr>
                            <tr v-if="globalstarData.stuMessage
                                    && globalstarData.stuMessage.data != null 
                                    && globalstarData.stuMessage.data.temperaturaIMU != null 
                                    && (globalstarData.stuMessage.data.temperaturaIMU * 10) != 0x7FFF">
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-chart-bar-32" size="sm" type="primary" @click="showDetalleTemperatura = true"></base-button>
                                <b>Temperatura IMU</b>
                              </td>
                              <td>{{ globalstarData.stuMessage.data.temperaturaIMU }} ˚C</td>
                            </tr>
                            <tr v-else-if="globalstarData.stuMessageBefore
                                    && globalstarData.stuMessageBefore.data != null 
                                    && globalstarData.stuMessageBefore.data.temperaturaIMU != null 
                                    && (globalstarData.stuMessageBefore.data.temperaturaIMU * 10) != 0x7FFF">
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-chart-bar-32" size="sm" type="primary" @click="showDetalleTemperatura = true"></base-button>
                                <b>Temperatura IMU</b>
                              </td>
                              <td>{{ globalstarData.stuMessageBefore.data.temperaturaIMU }} ˚C - NLD</td>
                            </tr>
                            <tr v-else>
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-chart-bar-32" size="sm" type="primary" @click="showDetalleTemperatura = true"></base-button>
                                <b>Temperatura IMU</b>
                              </td><td>Sin información</td>
                            </tr>
                            <tr v-if="(this.datosEquipo.ID == '0-4739095')
                                    && globalstarDataSAT_CT_001.stuMessage
                                    && globalstarDataSAT_CT_001.stuMessage.data != null 
                                    && globalstarDataSAT_CT_001.stuMessage.data.temperaturaSE != null 
                                    && (globalstarDataSAT_CT_001.stuMessage.data.temperaturaSE * 10) != 0x7FFF">
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-chart-bar-32" size="sm" type="primary" @click="showDetalleTemperatura = true"></base-button>
                                <b>Temperatura Ambiente</b>
                              </td>
                              <td>{{ globalstarDataSAT_CT_001.stuMessage.data.temperaturaSE }} ˚C</td>
                            </tr>
                            <tr v-else-if="(this.datosEquipo.ID == '0-4739095')
                                    && globalstarDataSAT_CT_001.stuMessageBefore
                                    && globalstarDataSAT_CT_001.stuMessageBefore.data != null 
                                    && globalstarDataSAT_CT_001.stuMessageBefore.data.temperaturaSE != null 
                                    && (globalstarDataSAT_CT_001.stuMessageBefore.data.temperaturaSE * 10) != 0x7FFF">
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-chart-bar-32" size="sm" type="primary" @click="showDetalleTemperatura = true"></base-button>
                                <b>Temperatura Ambiente</b>
                              </td>
                              <td>{{ globalstarDataSAT_CT_001.stuMessageBefore.data.temperaturaSE }} ˚C - NLD</td>
                            </tr>
                            <tr v-else-if="globalstarData.stuMessage
                                    && globalstarData.stuMessage.data != null 
                                    && globalstarData.stuMessage.data.temperaturaSE != null 
                                    && (globalstarData.stuMessage.data.temperaturaSE * 10) != 0x7FFF">
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-chart-bar-32" size="sm" type="primary" @click="showDetalleTemperatura = true"></base-button>
                                <b>Temperatura Ambiente</b>
                              </td>
                              <td>{{ globalstarData.stuMessage.data.temperaturaSE }} ˚C</td>
                            </tr>
                            <tr v-else-if="globalstarData.stuMessageBefore
                                    && globalstarData.stuMessageBefore.data != null 
                                    && globalstarData.stuMessageBefore.data.temperaturaSE != null 
                                    && (globalstarData.stuMessageBefore.data.temperaturaSE * 10) != 0x7FFF">
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-chart-bar-32" size="sm" type="primary" @click="showDetalleTemperatura = true"></base-button>
                                <b>Temperatura Ambiente</b>
                              </td>
                              <td>{{ globalstarData.stuMessageBefore.data.temperaturaSE }} ˚C - NLD</td>
                            </tr>
                            <tr v-else>
                              <td style="padding-right:10px">
                                <base-button icon="ni ni-chart-bar-32" size="sm" type="primary" @click="showDetalleTemperatura = true"></base-button>
                                <b>Temperatura Ambiente</b></td>
                              <td>Sin información</td>
                            </tr>
                          </div>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-xl-6 col-lg-4">
                      <div
                        id="map"
                        class="map-canvas"
                        style="height: 250px"
                      ></div>
                    </div>
                  </div>
                </template>
              </stats-card>
            </div>
          </div>

          <!-- Ultimos Datos RTU -->
          <div class="row w-100" >
            <div class="colum w-100">
              <stats-card
                v-if="this.sectionsElementView.infoOnlineRTU"
                title="Datos Equipo"
                type="gradient-orange"
                sub-title=""
                icon="ni ni-bullet-list-67"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <Message v-if="(globalstarData.stuMessage && (Date.now() - globalstarData.stuMessage.timestamp > this.timeAlerts.error))"       
                            severity="error" :closable="false">Equipo sin datos desde: {{ formatDate(globalstarData.stuMessage.timestamp, 'DD-MM-YYYY HH:mm') }} hrs</Message>
                  <Message v-else-if="(globalstarData.stuMessage && (Date.now() - globalstarData.stuMessage.timestamp > this.timeAlerts.warn))"       
                            severity="warn" :closable="false">Equipo sin datos desde: {{ formatDate(globalstarData.stuMessage.timestamp, 'DD-MM-YYYY HH:mm') }} hrs</Message>
                  <div class="row">
                    <div class="col-xl-6 col-lg-4">
                      <table class="w-100">
                        <tbody>
                          <div class="w-100">
                            <tr>
                              <td style="padding-right:10px"><b>Latitud Actual</b></td>
                              <td style="padding-right:50px">{{ RTUData.latitud }}</td>
                            </tr>
                            <tr>
                              <td style="padding-right:10px"><b>Longitud Actual</b></td>
                              <td style="padding-right:50px">{{ RTUData.longitud }}</td>
                            </tr>
                            <tr>
                              <div id="chart">
                                <apexchart type="radialBar" height="350" :options="chartOptions" :series="series"></apexchart>
                              </div>
                            </tr>
                            <tr>
                              <td style="padding-right:10px"><b>Marcha</b></td>
                            </tr>                            
                            <tr>
                              <td style="padding-right:10px">
                                <Button :label="RTUData.locomotive_transmission_msg" class="p-button-raised p-button-default"/>
                              </td>
                            </tr>
                            <tr>
                              &nbsp;
                            </tr>
                            <tr>
                            </tr>
                          </div>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-xl-6 col-lg-4">
                      <div
                        id="map"
                        class="map-canvas"
                        style="height: 250px"
                      ></div>
                    </div>
                  </div>
                  <div class="row">
                    <Button label="CLAXON" class="p-button-raised p-button-default"/>
                    <Button label="DIN B" class="p-button-raised p-button-default"/>
                    <Button label="PATINAJE" class="p-button-raised p-button-default"/>
                    <Button label="FALLA GR" class="p-button-raised p-button-default"/>
                    <Button label="REVERSOR" class="p-button-raised p-button-default"/>
                    <Button label="DET. MD" class="p-button-raised p-button-default"/>
                    <Button label="PCS OPEN" class="p-button-raised p-button-default"/>
                  </div>
                </template>
              </stats-card>
            </div>
          </div>
          
          <!-- Estado Garantía Equipo -->
          <div class="row w-100" >
            <div class="colum w-100">
              <stats-card
                v-if="this.sectionsElementView.estadoGarantia"
                title="Estado garantía"
                type="gradient-orange"
                sub-title="Vigente"
                icon="ni ni-time-alarm"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <div class="row">
                    <div class="col-auto">
                      <table >
                        <tbody>
                          <tr>
                              <td><b>Documentos Garantía</b></td><td>
                              <base-button
                                v-model="LinkArchivo"
                                icon="pi pi-paperclip"
                                size="sm"
                                type="secondary"
                                :id="JSON.stringify(datosEquipo.listAdjuntos)"
                                @click="showUploadFiles('garantia', $event)"></base-button>

                              <base-button v-if="(usertype == 1) || (usertype == 4)"
                                v-model="uploadFile"
                                icon="pi pi-cloud-upload"
                                size="sm"
                                type="primary"
                                @click="uploadFiles($event)"></base-button>
                              </td></tr>
                            <tr><td style="padding-right:10px"><b>Fecha expiración garantía</b></td><td>{{formatDate(datosEquipo.fechaFinGarantia, 'DD-MM-YYYY')}}</td></tr>
                          <tr v-if="datosEquipo.mesesGarantia"><td style="padding-right:10px"><b>Meses garantía</b></td><td>{{datosEquipo.mesesGarantia}}</td></tr>
                          <tr><td style="padding-right:10px"><b>Fabricante</b></td><td v-if="datosEquipo.mesesGarantia">{{datosEquipo.fabricante}}</td><td v-else>Sin información</td></tr>
                          <tr><td style="padding-right:10px"><b>Proveedor</b></td><td>{{datosEquipo.proveedor}}</td></tr>
                          <tr><td style="padding-right:10px"><b>Fecha Puesta En Servicio (PES)</b></td><td v-if="datosEquipo.fechaPES">{{formatDate(datosEquipo.fechaPES, 'DD-MM-YYYY')}}</td><td v-else>Sin información</td></tr>
                        </tbody>
                      </table>
                      <div v-if="datosEquipo.falla">
                        <div v-if="useremail"  class="my-3">
                          <!-- <Button label="Informar solución de falla" class="p-button-raised p-button-success" @click="showModalAgregarComentario = true"/> -->
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="useremail"  class="my-3">
                          <Button label="Reportar falla equipo" class="p-button-raised p-button-danger" @click="showModalReportarFalla = true"/>
                        </div>
                        <div v-else class="my-3">
                          <Button label="¿reportar equipo en falla?" class="p-button-raised p-button-info" @click="login"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </stats-card>
            </div>
          </div>
            
          <!-- Detalles Equipo -->
          <div class="row w-100" >  
            <div class="colum w-100">
              <stats-card
                v-if="this.sectionsElementView.detallesEquipo"
                title="Detalles Equipo"
                type="gradient-orange"
                sub-title=""
                icon="ni ni-settings-gear-65"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <div class="row">
                    <div class="col-auto">
                      <table >
                        <tbody>
                          <tr><td style="padding-right:10px"><b>Nombre del Equipo</b></td><td>{{datosEquipo.nombreEquipo}}</td></tr>
                          <tr><td style="padding-right:10px"><b>Descripción del Equipo</b></td><td>{{datosEquipo.descripcion}}</td></tr>
                          <tr><td style="padding-right:10px"><b>Número de serie</b></td><td v-if="datosEquipo.numeroSerie">{{datosEquipo.numeroSerie}}</td><td v-else>Sin información</td></tr>
                          <tr><td style="padding-right:10px"><b>Modelo Equipo</b></td><td v-if="datosEquipo.modelo">{{datosEquipo.modelo}}</td><td v-else>Sin información</td></tr>
                          <tr><td style="padding-right:10px"><b>Fabricante</b></td><td v-if="datosEquipo.fabricante">{{datosEquipo.fabricante}}</td><td v-else>Sin información</td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </stats-card>
            </div>
          </div>
            
          <!-- Informacion Adicional Equipo -->
          <div class="row w-100" >              
            <div class="colum w-100">
              <stats-card
                v-if="this.sectionsElementView.informacionAdicional"
                title="Informacion adicional"
                type="gradient-red"
                sub-title=""
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <div class="row">
                    <div class="col-auto">
                      <table >
                        <tbody>
                          <!-- <tr><td><b>Fotos Equipo</b></td><td>{{datosEquipo.listImagenes}} -->
                          <tr><td><b>Fotos Equipo</b></td><td>
                            <base-button
                              v-model="LinkArchivo"
                              icon="pi pi-paperclip"
                              size="sm"
                              type="secondary"
                              :id="JSON.stringify(datosEquipo.listImagenes)"
                              @click="showUploadFiles('fotoEquipo', $event)"></base-button>
                              <base-button v-if="(usertype == 1) || (usertype == 4)"
                                v-model="uploadFile"
                                icon="pi pi-cloud-upload"
                                size="sm"
                                type="primary"
                                @click="uploadPhotos($event)"></base-button>
                            </td></tr>
                          <tr><td><b>Manuales Técnicos</b></td><td>
                            <base-button
                              v-model="LinkArchivo"
                              icon="pi pi-paperclip"
                              size="sm"
                              type="secondary"
                              :id="JSON.stringify(datosEquipo.listAdjuntos)"
                              @click="showUploadFiles('manuales', $event)"></base-button>
                              <base-button v-if="(usertype == 1) || (usertype == 4)"
                                v-model="uploadFile"
                                icon="pi pi-cloud-upload"
                                size="sm"
                                type="primary"
                                @click="uploadManuales($event)"></base-button>
                          </td></tr>
                          <tr><td><b>Documentos administrativos</b></td><td>
                            <base-button
                              v-model="LinkArchivo"
                              icon="pi pi-paperclip"
                              size="sm"
                              type="secondary"
                              :id="JSON.stringify(datosEquipo.listDocumentos)"
                              @click="showUploadFiles('docs', $event)"></base-button>
                              <base-button v-if="(usertype == 1) || (usertype == 4)"
                                v-model="uploadFile"
                                icon="pi pi-cloud-upload"
                                size="sm"
                                type="primary"
                                @click="uploadDocs($event)"></base-button>
                            </td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </template>
              </stats-card>
            </div>
          </div>

          <!-- Historial -->          
          <div class="row w-100" >
            <div class="colum w-100">
              <Panel v-if="this.sectionsElementView.historial" class="mb-4 mb-xl-3" header="Historial" :toggleable="true" :collapsed="true">
                  <!-- 
                  <template #icons>
                      <button class="p-panel-header-icon p-link mr-2" @click="toggle">
                        <span class="pi pi-cog"></span>
                      </button>
                  </template>
                    -->

                  <div class="row" style="margin-top: 10px">
                    <div class="col-12">
                      <template v-if="datosEquipo.historial?.length === 0">
                        Sin cambios registrados.
                      </template>
                      <template v-else>
                        <DataTable
                          :value="datosEquipo.historial"
                          :paginator="datosEquipo.historial?.length > 5"
                          :rows="10"
                          :rowsPerPageOptions="[10, 25, 50]"
                          v-model:filters="filtersTableHistorial"
                          filterDisplay="menu"
                          :sortable="true"
                          :globalFilterFields="['fecha', 'estado', 'observacion']"
                          responsiveLayout="scroll"
                        >
                          <Column header="Fecha">
                            <template #body="body">
                              {{ formatDate(body.data.fecha, 'DD-MM-YYYY') }}
                            </template>
                          </Column>
                          <Column field="estado" header="Estado">
                            <template #body="body">
                              <span style="text-transform: capitalize">
                                {{ body.data.estado.toLowerCase() }}
                              </span>
                            </template>            
                          </Column>
                          <Column field="observacion" header="Observación"></Column>
                          <Column field="user" header="Realizada por">
                            <template #body="body">
                              {{ processUser(body.data.user) }}
                            </template>
                          </Column>
                        </DataTable>
                      </template>
                    </div>
                  </div>

                  <div v-if="useremail">
                    <p>Agregar comentarios</p>
                    <Button label="Agregar comentario" class="p-button-raised p-button-info" @click="showModalAgregarComentario = true"/>
                    <!-- <Button label="Reportar falla equipo" class="p-button-raised p-button-danger" @click="reportarFalla"/> -->
                  </div>
                  <div v-else  class="my-3">
                    <Button label="¿desea agregar un comentario?" class="p-button-raised p-button-info" @click="login"/>
                  </div>
              </Panel>
            </div>
          </div>

        </div>

        <!-- Tarjetas (Fichas) Columna Derecha -->
        <div class="col-xl-5 col-lg-4">
          <div class="row" >
            <div class="colum">
              <stats-card
                v-if="this.sectionsElementView.fichaProveedor"
                title="Ficha Proveedor"
                type="gradient-orange"
                sub-title=""
                icon="ni ni-support-16"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <div class="row" >
                    <div class="column" >
                      <table >
                        <tbody>
                          <tr><td style="padding-right:10px"><b>Proveedor</b></td><td>{{ datosEquipo.proveedor }}</td></tr>
                          <tr v-if="datosEquipo.IDProveedor"><td style="padding-right:10px"><b>ID Proveedor</b></td><td>{{ datosEquipo.IDProveedor }}</td></tr>                    
                          <tr v-if="datosEquipo.contactoProveedor"><td style="padding-right:10px"><b>Nombre Contacto</b></td><td>{{ datosEquipo.contactoProveedor }}</td></tr>
                          <tr v-else><td style="padding-right:10px"><b>Nombre Contacto</b></td><td>Sin información</td></tr>
                          <tr><td style="padding-right:10px"><b>Telefono</b></td><td>{{ datosEquipo.fonoProveedor }}</td></tr>
                          <tr><td style="padding-right:10px"><b>Email</b></td><td>{{ datosEquipo.emailProveedor }}</td></tr>

                          <!-- <tr><td><b>Contrato</b></td><td>{{datosEquipo.idContrato.split("/")[0]}}</td></tr> -->
                          <!-- <tr><td><b>Fono</b></td><td>{{datosEquipo.idContrato.split("/")[1]}}</td></tr> -->
                          <!-- <tr><td><b>Email</b></td><td>{{datosEquipo.idContrato.split("/")[2]}}</td></tr> -->

                          <!-- <tr><td><b>Identificador {{ this.clientName }}</b></td><td>{{datosEquipo.ID}}</td></tr> -->

                          <!-- <tr><td><b>Número de serie</b></td><td>{{datosEquipo.numeroSerie}}</td></tr> -->

                          <!-- <tr><td><b>Nombre del Equipo</b></td><td>{{datosEquipo.nombreEquipo}}</td></tr> -->
                          <!-- <tr><td><b>Descripción del Equipo</b></td><td>{{datosEquipo.descripcion}}</td></tr> -->

                          <tr v-if="datosEquipo.fabricante"><td style="padding-right:10px"><b>Fabricante</b></td><td>{{ datosEquipo.fabricante }}</td></tr>
                          <tr v-if="datosEquipo.modelo"><td style="padding-right:10px"><b>Modelo Equipo</b></td><td>{{ datosEquipo.modelo }}</td></tr>
                          <tr v-if="datosEquipo.comentarios"><td style="padding-right:10px"><b>Comentarios</b></td><td>{{ datosEquipo.comentarios }}</td></tr>

                          <!-- <tr><td><b>Año fabricacion</b></td><td>{{datosEquipo.anoFabricacion}}</td></tr> -->

                          <!-- <tr><td><b>Fecha de PES</b></td><td>{{datosEquipo.fechaPES}}</td></tr> -->
                          <!-- 
                        <tr><td><b>Manuales Técnicos</b></td><td>
                          <base-button
                            v-model="LinkArchivo"
                            icon="pi pi-paperclip"
                            size="sm"
                            type="secondary"
                            :id="JSON.stringify(datosEquipo.listAdjuntos)"
                            @click="showUploadFiles('', $event)"></base-button>
                        </td></tr>
                        -->
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="mb-4 my-xl-5">
                    <table>
                      <tbody>
                        <tr v-if="datosEquipo.proyecto" style="text-align: center">Proyecto: {{ datosEquipo.proyecto.replace(/proyecto/gi, "") }}</tr>
                        <tr v-if="datosEquipo.urlQR" class="d-flex align-items-center justify-content-center"><qrcode-vue class="mb-4 my-xl-1" :value="datosEquipo.urlQR" :size="200" level="H"/></tr>
                        <tr v-else class="d-flex align-items-center justify-content-center"><qrcode-vue class="mb-4 my-xl-1" :value="qrURL" :size="200" level="H"/></tr>
                        <tr v-if="datosEquipo.TAG" style="text-align: center">TAG: {{datosEquipo.TAG}}</tr>
                        <tr v-if="datosEquipo.nombreCortoEquipo" style="text-align: center">Equipo: {{datosEquipo.nombreCortoEquipo}}</tr>
                        <tr v-else-if="datosEquipo.nombreEquipo" style="text-align: center">Equipo: {{datosEquipo.nombreEquipo}}</tr>
                        <tr v-if="datosEquipo.plano" style="text-align: center">Plano: {{datosEquipo.plano}}</tr>
                      </tbody>
                    </table >
                  </div>
                </template>
              </stats-card>
          </div>
          </div>
          <div class="row" >
            <div class="colum">
              <stats-card
                v-if="this.sectionsElementView.fichaTecnica"
                title="Ficha Técnica"
                type="gradient-orange"
                sub-title=""
                icon="ni ni-support-16"
                class="mb-4 mb-xl-3"
              >
                <template v-slot:footer>
                  <div class="row" >
                    <div class="column" >
                      <table >
                        <tbody>
                          <!-- <tr><td><b>Identificador {{ this.clientName }}</b></td><td>{{datosEquipo.ID}}</td></tr> -->
                          <tr><td><b>Número de serie</b></td><td>{{ datosEquipo.numeroSerie }}</td></tr>
                          <!-- <tr><td><b>Nombre del Equipo</b></td><td>{{datosEquipo.nombreEquipo}}</td></tr> -->
                          <!-- <tr><td><b>Descripción del Equipo</b></td><td>{{datosEquipo.descripcion}}</td></tr> -->
                          <tr><td><b>Fabricante</b></td><td>{{ datosEquipo.fabricante }}</td></tr>
                          <tr><td><b>Proveedor</b></td><td>{{ datosEquipo.proveedor }}</td></tr>
                          <tr><td><b>Modelo Equipo</b></td><td>{{ datosEquipo.modelo }}</td></tr>
                          <!-- <tr><td><b>Comentarios (tension kV)</b></td><td>{{datosEquipo.comentarios}}</td></tr> -->
                          <tr><td><b>Comentarios</b></td><td>{{ datosEquipo.comentarios }}</td></tr>
                          <tr><td><b>Año fabricacion</b></td><td>{{ datosEquipo.anoFabricacion }}</td></tr>
                          <!-- <tr><td><b>Fecha de PES</b></td><td>{{datosEquipo.fechaPES}}</td></tr> -->
                          <tr><td><b>Manuales Técnicos</b></td><td>
                            <base-button
                              v-model="LinkArchivo"
                              icon="pi pi-paperclip"
                              size="sm"
                              type="secondary"
                              :id="JSON.stringify(datosEquipo.listAdjuntos)"
                              @click="showUploadFiles('', $event)"></base-button>
                          </td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </stats-card>
          </div>
          </div>
        </div>
        
      </div>
    </div>

  </div>
</template>
<script>
import VueApexCharts from "vue3-apexcharts";

import firebase from 'firebase';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage';  // If using Firebase storage
import Button from 'primevue/button';
import QrcodeVue from 'qrcode.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
// import Fieldset from 'primevue/fieldset';
import Panel from 'primevue/panel';
import Message from 'primevue/message';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import Tag from 'primevue/tag';

import { downloadFileFromURL, formatDate } from "@/components/utils";
import { getUserKeyAndType } from "@/model/functions";
import { getDeviceLastData } from "@/model/functions_powerline-ms";
import ModalCargaArchivos from '../components/Custom/ModalCargaArchivos.vue';

import GraficoTemperatura from "./Dashboard/PowerlineMS/TemperaturaEquipo";
import GraficoAltura from "./Dashboard/PowerlineMS/AlturaEquipo";
import GraficoAceleraciones from "./Dashboard/PowerlineMS/AcelerometroEquipo";
import GraficoGiroscopio from "./Dashboard/PowerlineMS/GiroscopioEquipo";

import { Loader } from "google-maps";

import 'primeflex/primeflex.css';

export default {

    props: {
      baseInventario: String, 
      // propX: {
      //   type: String,
      //   default: 'Vue!'
      // }
    },
    components: {
      Button,
      QrcodeVue,
      DataTable,
      Column,
      Dialog,
      Panel,
      Message,
      Textarea,
      Toast,
      Tag,
      ModalCargaArchivos,
      GraficoTemperatura,
      GraficoAltura,
      GraficoAceleraciones,
      GraficoGiroscopio,
  },
  data() {
    return {
      site: this.site,

      LinkArchivo: null, //ToDo: revisar referencia como v-model
      uploadFile: null, //ToDo: revisar referencia como v-model

      usertype: null,
      useremail: "",
      userDisplayName: "",
      showModalDetalleArchivos: false,
      listUploadedFiles: [],
      allFiles: [],

      loading: true,
      notFound: false,

      showModalReportarFalla: false,
      showModalAgregarComentario: false,
      showModalUploadFiles: false,
      showModalUploadPhotos: false,
      showModalUploadManuales: false,
      showModalUploadDocs: false,

      showDetalleTemperatura: false,
      showDetalleAltura: false,
      showDetalleAcelerometro: false,
      showDetalleGiroscopio: false,

      observacion: "",

      totalitems:0,
      totalCommissioning:0,
      total1YearOperation:0,
      totalCapital:0,

      elementid:this.$route.params.elementid,
      db_ref_element: (this.baseInventario)? 
          (this.db_ref_base_inventario + "/" + this.baseInventario + "/" + this.$route.params.elementid) : 
          this.db_ref_inventario + "/" + this.$route.params.elementid,

      datosEquipo: {},
      qrURL: "",

      Equipment:"",
      POrder:"",
      Supplier:"",
      SpareType:"",
      PartNumber:"",
      Status: "",
      StatusType: "",
      Completion: "",

      timestampLastAction: 0,

      globalstarData: {},
      globalstarDataSAT_CT_001: {},

      RTUData: { 
        latitud: "",
        longitud: "",
        locomotive_transmission: "",
        locomotive_transmission_msg: "",
      },

      //RTU Data
      series: [0],
      chartOptions: {
        chart: {
          height: 350,
          type: 'radialBar',
          offsetY: -10
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: '16px',
                color: undefined,
                offsetY: 80
              },
              value: {
                offsetY: 26,
                fontSize: '28px',
                color: undefined,
                formatter: function (val) {
                  return val + " km/h";
                }
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
              shade: 'dark',
              shadeIntensity: 0.15,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 65, 91]
          },
        },
        stroke: {
          dashArray: 4
        },
        labels: ['Velocidad'],
      },
    };
  },
  methods:
  {
    formatDate,

    isDuplicatedCall()
    {
      //ToDo: resolver tema de llamada doble
      var timestamp = Date.now();
      console.log("deltaT: " + (timestamp - this.timestampLastAction));
      if(timestamp - this.timestampLastAction > 2000)
      {
        this.timestampLastAction = timestamp;
        return false;
      }
      else
      {
        console.log("----- double call exit -----");
        return true;
      }        
    },    


    toggle(event) {
        this.$refs.menu.toggle(event);
    },

    processUser(user)
    {
      return user;
    },

    login()
    {
      /*
       No es el path lo que se pasa, sino el 'name', por eso se pasa en params path ->this.$route.name. 
       Inicialmente se pasaba path ->"Detalle equipo" lo que llevaba a "/element/:elementid", 
       pero ahora está también el name "Informacion equipo" que lleva a "/item/:elementid"
       */
      // this.$router.push({ name: "login", params: { path: "Detalle equipo", elementid: this.elementid } });
      this.$router.push({ name: "login", params: { path: this.$route.name, elementid: this.elementid } });
    },

    reportarFalla(comentarioFalla){
      console.log("reportarFalla::%s/%s", this.db_ref_element, this.elementid);

      if(this.isDuplicatedCall()) return;

      let fechaReporte = Date.now();
      firebase.database().ref(this.db_ref_element).update({ falla:true, fechaFalla: fechaReporte});

      const numSolicitud = String(fechaReporte).slice(0, 10);

      var newPost = firebase.database().ref(this.db_ref_solicitudes).push();
      newPost.set({
        numSolicitud: numSolicitud,
        elementid: this.elementid,
        fecha: fechaReporte,
        estado: "pendiente",
        reporteFalla: comentarioFalla,
        // reportadoPor: this.useremail,
        reportadoPor: this.userDisplayName,
        ...this.datosEquipo
      });

      const keySolicitud = newPost.key;

      var newPost = firebase.database().ref(this.db_ref_element + "/historial").push();
      newPost.set({
        fecha: fechaReporte,
        estado: "Falla",
        observacion: 'Se genera una solicitud de ejecución de garantía (solicitud nº ' +  numSolicitud + ')',
        user: this.userDisplayName,
      });

      try { //Envio de Mail
        const urlSolicitud = this.urlBase + "/#/solicitudes/" + keySolicitud;

        var htmlEmail= 'Se reporta falla en equipo en garantía ' + this.datosEquipo.nombreEquipo + ".<br/>"
            + 'Se ha generado la solicitud <strong>' + numSolicitud + '</strong>, la cual ha sido asignada asignada a ' 
            + this.datosEquipo.ingenieroContrato + " (" + this.datosEquipo.mailIngenieroContrato + ")" 
            +'<br>Para acceder a la solicitud haga click en:  <a href="'+ urlSolicitud +'">solicitud nº '+ numSolicitud +'</a>';
        var textoEmail = 'Se reporta falla en equipo en garantía ' + this.datosEquipo.nombreEquipo + "."
            + 'Se ha generado la solicitud ' + numSolicitud + ', la cual ha sido asignada asignada a ' 
            + this.datosEquipo.ingenieroContrato + " (" + this.datosEquipo.mailIngenieroContrato + ")";

        const msg = {
          to: this.datosEquipo.mailIngenieroContrato, //'maguilar@gc-hub.tech',
          from: 'e-garant@pcvuelat.com',
          subject: 'Nueva solicitud de ejecución de garantía (solicitud nº ' +  numSolicitud + ')',
          text: textoEmail,
          html: htmlEmail ,
        }

        let sendEmail = firebase.functions().httpsCallable('sendEmail');
        sendEmail(msg)
        .then((result) => {
          if(result.data && result.data.result == true)
          {
            console.log("sendEmail result ->", result);
          }
        })
        .catch((error) => {
          console.log(error);
        })
      }
      catch(error)
      {
        console.log(error);
      }

      this.$toast.add({
        severity: "success",
        summary: "Falla reportada",
        detail:'Se ha generado las solicitud ' + numSolicitud + ' al area de soporte, asignada a ' 
          + this.datosEquipo.ingenieroContrato + " (" + this.datosEquipo.mailIngenieroContrato + ")",
        life: 5000,
      });
      this.showModalReportarFalla = false;
      this.observacion = "";
    },

    agregarComentario(comentario, estado){
      console.log("agregarComentario::%s/%s", this.db_ref_element, this.elementid);

      var newPost = firebase.database().ref(this.db_ref_element + "/historial").push();
      newPost.set({
        fecha: Date.now(),
        estado: estado,
        observacion: comentario,
        user: this.userDisplayName,
      });
      this.$toast.add({
        severity: "success",
        summary: "comentario agregado",
        // detail:'Se ha generado una solicitud al area de soporte',
        life: 5000,
      });
      this.showModalAgregarComentario = false;
      this.observacion = "";
    },

    downloadFile: function(body, event) {
      event.preventDefault();
      if(this.isDuplicatedCall()) return;
      console.log('downloadFile--------');      
      downloadFileFromURL(body.data.url.url, body.data.url.name);      
    },

    showUploadFiles: function(category, event) {
      event.preventDefault();
      if(this.isDuplicatedCall()) return;
      //console.log("showUploadFiles---------" + event.currentTarget.id);

      this.listUploadedFiles.length = 0;

      this.listUploadedFiles = Object.values(this.allFiles).filter(item => {
        return item.categoria === category;
      });

      console.log("---filtrado---")
      console.log(this.listUploadedFiles);

      // if(this.datosEquipo.listAdjuntos && this.datosEquipo.listAdjuntos !== "NA")
      // {
      //   var items = JSON.parse(event.currentTarget.id);
      //   // var items = JSON.parse(this.datosEquipo.listAdjuntos);
      //   // this.listUploadedFiles = JSON.parse(event.currentTarget.id);

      //   items.forEach(element => {
      //     this.listUploadedFiles.push( {
      //       NombreArchivo:element.nombre,
      //       CategoriaArchivo:element.categoria,
      //       FechaCarga:formatDate(element.fecha, 'DD-MM-YYYY'),
      //       LinkArchivo:JSON.stringify({downloadLink:element.url, nombreArchivo:element.nombre}),
      //     });
      //   });
      // }

      this.showModalDetalleArchivos = true;
    },
    uploadFiles: function(event) {
      console.log("entra a Upload Files");

      
      this.showModalUploadFiles = true;
    },
    uploadPhotos: function(event) {
      console.log("entra a uploadPhotos");

      
      this.showModalUploadPhotos = true;
    },
    uploadManuales: function(event) {
      console.log("entra a uploadManuales");

      
      this.showModalUploadManuales = true;
    },
    uploadDocs: function(event) {
      console.log("entra a uploadDocs");

      
      this.showModalUploadDocs = true;
    },
    buscarArchivos(elementid){
      // console.log("entra a buscarArchivos")
      const cargasRef = firebase.database().ref('cargas');      
      const filterById = cargasRef.orderByChild('id').equalTo(elementid);      
      filterById.on('value', snapshot =>{ this.allFiles = snapshot.val() })      
    },
    google: function(lat, lon){
      // Regular Map
      const myLatlng = new google.maps.LatLng(lat, lon);
      const mapOptions = {
        zoom: 15,
        center: myLatlng,
        scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
        disableDefaultUI: true, // a way to quickly hide all controls
        zoomControl: true,
        styles: [
          {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [{ color: "#444444" }],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [{ color: "#f2f2f2" }],
          },
          {
            featureType: "poi",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "road",
            elementType: "all",
            stylers: [{ saturation: -100 }, { lightness: 45 }],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [{ visibility: "simplified" }],
          },
          {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [{ visibility: "off" }],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [{ color: "#5e72e4" }, { visibility: "on" }],
          },
        ],
      };
      const map = new google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );
      const marker = new google.maps.Marker({
        position: myLatlng,
        title: "Regular Map!",
      });
      marker.setMap(map);
    },
  },

  mounted() 
  {
    //READING PARAMETER
    console.log(this.elementid);
    console.log(this.baseInventario);

    // console.log(this.$route);
    // console.log(this.$route.path);
    // console.log(this.$route.fullPath);
    // console.log(this.$route.name);

    // console.log(this.$route.params);
    // console.log(this.$route.matched);

    if (this.elementid =="-MyhpzHfs5QZf2xKpGPx")
    {
      window.location.href = "https://e-garant-bhp.pcvuelat.com/#/element/-Ndp112jAnvqCzhXH209"
      // window.location = url;
      return;
    }
        
    // this.qrURL = this.urlBase + "/#/cl/" + this.elementid;
    this.qrURL = this.urlBase + "/#" + this.$route.fullPath; //URL dinamica (se usa si es que dato de URL real instalado no está en la DB)
    // console.log("qrURL: " + this.qrURL);

    //Loader Google Maps
    if (this.googleMapsLoader == null) this.googleMapsLoader = new Loader(this.googleMapsAPIKey);
    const loader = this.googleMapsLoader;
    // loader.load().then(() => this.google(-33, -44));
    // loader.load().then(() => this.google(-33.459389, -70.679642));

    // console.log("db_ref_element: " + this.db_ref_element);
    firebase.database().ref(this.db_ref_element).on('value', (childSnapshot) => { 
      if(childSnapshot.exists())
      {
        this.datosEquipo = childSnapshot.val();
        console.log("this.datosEquipo", this.datosEquipo);

        if(this.sectionsElementView.infoOnlineEquipo)
        {
          if (this.datosEquipo.ID == "0-4739095") {
            getDeviceLastData(this.db_ref_stuMessages, "0-4734707", (datos) => {
              this.globalstarDataSAT_CT_001 = datos;
            });
          }

          getDeviceLastData(this.db_ref_stuMessages, this.datosEquipo.ID, (datos) => { 
            this.globalstarData = datos;
            if(datos.stuMessage && datos.stuMessage.data 
            && datos.stuMessage.data.latitud != null && datos.stuMessage.data.latitud != 0
            && datos.stuMessage.data.longitud != null && datos.stuMessage.data.longitud != 0)
            {
              loader.load().then(() => this.google(datos.stuMessage.data.latitud, datos.stuMessage.data.longitud));
            }
          });
        }
        
        if(this.sectionsElementView.infoOnlineRTU)
        {
          firebase.database().ref("TrackAndTrace/RTUData/realtime/" + this.datosEquipo.ID).once('value', (snapshot) => {
                if (snapshot.exists()) {
                    const response = snapshot.val();
                    this.RTUData = { ... response.gps_data , ... response.train_data };
                    this.globalstarData = { stuMessage: { data: { ... response.gps_data } } };
                    this.series[0] = parseInt(response.gps_data.speed);

                    // this.globalstarData = datos;
                    loader.load().then(() => this.google(response.gps_data.latitud, response.gps_data.longitud));
                }
                else {
                  // Nada
                }
            });
        }        
      }
      else
      {
        this.notFound = true;
      }
      this.loading = false;
    });
  },

  created()
  {
    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.useremail = user.email;
        console.log("USER SIGNED");

        getUserKeyAndType(this.db_ref_users, user.email, (userKey, usertype, data) => {
          this.userDisplayName = data.nombres + " " + data.apellidos;
          this.usertype = usertype;
        });
      } else {
        unsuscribe();
        console.log("USER NOT SIGNED"); //sólo puede ver info
      }
    });
    this.buscarArchivos(this.elementid);
    console.log("----Cargas----")
    console.log(this.listUploadedFiles)
  },

};
</script>

<style lang="scss" scoped>
.shadow-box {
    min-height: 100px;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin: 2rem;
    border-radius: 4px;
    background-color: var(--surface-e);
}

.p-button {
    margin-right: .5rem;
}

.p-buttonset {
    .p-button {
        margin-right: 0;
    }
}

.sizes {
    .button {
        margin-bottom: .5rem;
        display: block;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.fieldset p {
    line-height: 1.5;
    margin: 0;
}
</style>
