import firebase from "firebase";
import "firebase/database"; 

export function getDeviceLastData(db_ref_stuMessages, id_equipo, callback) {
    console.log("<·><><·> getDeviceLastData() <·><><·>");

    const deviceData = {};
    deviceData.timestampLastMessage = 0;
    deviceData.stuMessage = false;
    deviceData.stuMessageBefore = false;
    deviceData.healthMessage = false;
    deviceData.initFrameMessage = false;

    deviceData.ultimaAlturaValida = null; //workarround
    deviceData.ultimaTempCamaraValida = null;

    const filterByDeviceId = firebase.database().ref(db_ref_stuMessages + "/" + id_equipo)
    filterByDeviceId.orderByChild('timestamp')//.limitToLast(10) //ToDo: revisar tema de ultimos datos para no iterar por todos
        .on('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                if (childSnapshot.val().type == "stuMessage" ||
                    (childSnapshot.val().type == "stuResponseMsg" && childSnapshot.val().payload.length > 20)) {
                    deviceData.stuMessageBefore = deviceData.stuMessage;
                    deviceData.stuMessage = childSnapshot.val();
                    if (deviceData.stuMessage.timestamp > deviceData.timestampLastMessage) {
                        deviceData.timestampLastMessage = deviceData.stuMessage.timestamp;
                    }

                    //Registro de ultima altura válida
                    if (deviceData.stuMessage.data != null && deviceData.stuMessage.data.alturaActual != null &&
                        (deviceData.stuMessage.data.alturaActual * 10) != 0xFFFF &&
                        (deviceData.stuMessage.data.alturaActual * 10) != 0xBF20) {
                        // deviceData.ultimaAlturaValida = deviceData.stuMessage.data.alturaActual;

                        //workarround
                        if (deviceData.ultimaAlturaValida != null && ((deviceData.ultimaAlturaValida - deviceData.stuMessage.data.alturaActual) / 100.0) > 5) {
                            //ToDo: no alterar datos de stuMessage, sino agregar flag de dato inválido
                            //Si existe una disminución de altura mayor a 5 metros, se considera lectura no válida:
                            deviceData.stuMessage.data.alturaActual = 0xBF20 / 10;
                            if (((deviceData.ultimaAlturaValida - deviceData.stuMessage.data.alturaAnterior) / 100.0) > 5) {
                                deviceData.stuMessage.data.alturaAnterior = 0xBF20 / 10;
                            }
                        }
                        else {
                            deviceData.ultimaAlturaValida = deviceData.stuMessage.data.alturaActual;
                        }
                    }

                    //Registro de ultima ultima temperatura camara (Temperatura Conductor) válida
                    if (deviceData.stuMessage.data != null && deviceData.stuMessage.data.temperaturaCamara != null &&
                        (deviceData.stuMessage.data.temperaturaCamara * 10) > -15 &&
                        (deviceData.stuMessage.data.temperaturaCamara * 10) != 0x7FFF) {
                        deviceData.ultimaTempCamaraValida = deviceData.stuMessage.data.temperaturaCamara;
                    }
                }
                else if (childSnapshot.val().type == "health message") {
                    deviceData.healthMessage = childSnapshot.val();
                    if (deviceData.healthMessage.timestamp > deviceData.timestampLastMessage) {
                        deviceData.timestampLastMessage = deviceData.healthMessage.timestamp;
                    }
                }
                else if (childSnapshot.val().type == "init frame message") {
                    deviceData.initFrameMessage = childSnapshot.val();
                    if (deviceData.initFrameMessage.timestamp > deviceData.timestampLastMessage) {
                        deviceData.timestampLastMessage = deviceData.initFrameMessage.timestamp;
                    }
                }

                // if (deviceData.stuMessage &&
                //     deviceData.healthMessage &&
                //     deviceData.initFrameMessage) {
                //     console.log("fin foreach");
                //     return true;
                // }
            });

            // console.log("deviceData", deviceData);
            callback(deviceData);
        });
}


export function getDeviceData(db_ref_stuMessages, id_equipo, callback) {
    console.log("<·><><·> getDeviceData() <·><><·>");

    let deviceData = [];

    const filterByDeviceId = firebase.database().ref(db_ref_stuMessages + "/" + id_equipo)
    filterByDeviceId.orderByChild('timestamp')//.limitToLast(200) //ToDo: revisar tema de ultimos datos para no iterar por todos
        .on('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                if (childSnapshot.val().type == "stuMessage" ||
                    (childSnapshot.val().type == "stuResponseMsg" && childSnapshot.val().payload.length > 20)) {
                    deviceData.push(childSnapshot.val());
                }
            });

            // console.log("deviceData", deviceData);
            callback(deviceData);
        });
}
