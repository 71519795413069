<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Ingresar con credenciales</small>
          </div>
          <form role="form">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-bind:value="model.email"
              v-on:input="setEmail"
              @keyup.enter.prevent="sendLogin()"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Contraseña"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-on:input="setPassword"
              @keyup.enter.prevent="sendLogin()"
            >
            </base-input>

            <!--<base-checkbox class="custom-control-alternative">
              <span class="text-muted">Recordarme</span>
            </base-checkbox>-->
            <div class="text-center">
              <base-button type="primary" class="my-4" style="background-color:#0072B1;" @click.prevent="sendLogin()">Ingrese</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <!-- <a href="#" class="text-light"><small>Contraseña olvidada?</small></a> -->
        </div>
        <div class="col-6 text-right">
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from 'firebase';
export default {
  
  name: "login",

  data() {
    return {
      memail:"",  
      model: {
          email: "",
          password: "",
        },
    };
  },
   methods: {

     setEmail(event)
     {
      //  console.log("setEmail");
       this.model.email= event.target.value;
     },
     setPassword(event)
     {
      //  console.log("setPassword");
       this.model.password= event.target.value;
     },

    sendLogin() {
      console.log("sending...", this.model);
      firebase
      .auth()
      .signInWithEmailAndPassword(this.model.email, this.model.password)
      .then(() => {
        console.log("loggedin", this.$route.params);
        if(this.$route.params.path)
        {
          //$route.params.path sólo debiera tomar los valores: "Detalle equipo" ó "Detalle solicitud"
          this.$router.push({ 
            name: this.$route.params.path, 
            params: { path: this.$route.params.path, elementid: this.$route.params.elementid } }); //Se vuelve a incluir el path para que el 2do llamado también vaya a "Detalle equipo" / "Detalle solicitud"
        }
        else
        {
          this.$router.push('/tables');
        }
      })
      .catch(error => {
        alert(error.message);
      });

    }
  },
};
</script>
<style></style>
