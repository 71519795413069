<template>
  <div>
    <base-header
      style="background-size: 100% 500px;background-image:linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.2)), url('img/layout/transelec/industry.jpg');"
      class="pb-6 pb-8 pt-5 pt-md-8">
      <link href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css" rel="stylesheet">
      <link href="https://unpkg.com/primevue/resources/primevue.min.css" rel="stylesheet">
      <link href="https://unpkg.com/primeicons/primeicons.css" rel="stylesheet">
    </base-header>

    <Dialog header="Crear Nuevo Equipo" v-model:visible="showModalCrearEquipo"
      :breakpoints="{'960px': '90vw', '640px': '100vw'}" :style="{width: '75vw'}">
      <ModalNuevoEquipo />
    </Dialog>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <!-- Card stats -->
          <div class="row">
            <div class="col-xl-3 col-lg-6">
              <stats-card title="Total equipos" type="gradient-green" v-bind:sub-title="totalitems"
                icon="ni ni-active-40" class="mb-4 mb-xl-0">
                <template v-slot:footer>
                  <span class="text-nowrap">&nbsp;</span>
                  <!-- <span class="text-nowrap">Elementos en lista</span> -->
                </template>
              </stats-card>
            </div>
            <div class="col-xl-3 col-lg-6">
              <stats-card title="Equipos en garantia" type="gradient-orange" v-bind:sub-title="totalitems"
                icon="ni ni-chart-pie-35" class="mb-4 mb-xl-0">
                <template v-slot:footer>
                  <span class="text-nowrap">&nbsp;</span>
                </template>
              </stats-card>
            </div>
            <div class="col-xl-3 col-lg-6">
              <stats-card title="En garantia próximos a vencer" type="gradient-info" v-bind:sub-title="'0'"
                icon="ni ni-money-coins" class="mb-4 mb-xl-0">
                <template v-slot:footer>
                  <!-- <span class="text-nowrap">&nbsp;</span> -->
                </template>
              </stats-card>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase";
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dialog from "primevue/dialog";
import Column from 'primevue/column';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import QrcodeVue from 'qrcode.vue';
import { getActivosGarantias } from "@/model/functions";
import ModalNuevoEquipo from '../components/Custom/ModalNuevoEquipo.vue';
import { formatDate, daysToDate } from "@/components/utils";

// import { setAgentesGarantias } from "@/model/functions";

export default {
  name: "loadData",
  components: {
    DataTable,
    Column,
    InputText,
    QrcodeVue,
    Button,
    ModalNuevoEquipo,
    Dialog
  },

  data() {
    return {
      site: this.site,

      useremail: "",
      totalitems: 0,
      totalEnFalla: 0,
      showModalCrearEquipo: false,

      loading: false,
    };
  },

  methods: {

    formatDate,
    daysToDate,

    select: function (event) {
      var targetId = event.currentTarget.id;
      console.log(targetId); // returns 'foo'
      this.$router.push("/element/" + targetId);
    },

    initTables(user) {
      console.log("<·><><·> initTables() <·><><·>");
      getActivosGarantias(this.db_ref_inventario, user, (datos) => {
        console.log("datos ->", datos);
        this.totalitems = datos.length;
      });      
    },
    modalAgregarEquipo(){
      console.log("Entra a crear equipo");
      this.showModalCrearEquipo = true;
    },
    loadData(){
      console.log("<·><><·> loadData() <·><><·>");

//ID equipo|Número de serie|Nombre del Equipo|Descripción del Equipo|Fabricante|Proveedor|Modelo Equipo|Ubicación|Área Responsable|Comentarios (tension kV)|Numero contrato|Año fabricacion|Fecha de PES|Meses|Fecha garantía hasta|Expiración Garantía|Fotos Equipo|Manuales Técnicos|Documentos administrativos|Categoría Equipo|Criticidad|tipo|subtipo|codigoEstructura|ID2|Codigo SAP|Condiciones especiales expiracion garantía|Rut proveedor|Contacto proveedor|fono proveedor|email proveedor|TAG|ADC|Contrato|Ingeniero Contrato|Mail Ingeniero Contrato|Proyecto|Nombre Corto|Plano|TAG Equipo Principal
let datos =
'\
0001||Bomba Vertical|||Neptuno||Piscina Colectora de Drenajes|||9100060389||||12/19/2023|12/19/2023||||||||||||||56572424685|info@neptuno.cl|0620-PP-0101||Bombas de Turbina Vertical|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Bomba Vertical|LSTS2-0620-PP-PID-55301|\n\
0002||Bomba Vertical|||Neptuno||Piscina Colectora de Drenajes|||9100060389||||12/19/2023|12/19/2023||||||||||||||56572424685|info@neptuno.cl|0620-PP-0102||Bombas de Turbina Vertical|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Bomba Vertical|LSTS2-0620-PP-PID-55301|\n\
0003||Bomba Vertical|||Neptuno||Piscina Colectora de Drenajes|||9100060389||||12/19/2023|12/19/2023||||||||||||||56572424685|info@neptuno.cl|0620-PP-0103||Bombas de Turbina Vertical|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Bomba Vertical|LSTS2-0620-PP-PID-55301|\n\
0004||Bomba Vertical|||Neptuno||Piscina Colectora de Drenajes|||9100060389||||12/19/2023|12/19/2023||||||||||||||56572424685|info@neptuno.cl|0620-PP-0104||Bombas de Turbina Vertical|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Bomba Vertical|LSTS2-0620-PP-PID-55301|\n\
0005||Bomba de Pozo|||Xylem||Sistema de Pozos|||4511554298||||4/1/2025|4/1/2025||||||||||||||56974301024|juan.troncoso@xylem.com|0620-PP-0107||Bombas verticales de Pozo Profundo|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Bomba de Pozo|LSTS2-0620-PP-PID-55401|\n\
0006||Bomba de Pozo|||Xylem||Sistema de Pozos|||4511554298||||4/1/2025|4/1/2025||||||||||||||56974301024|juan.troncoso@xylem.com|0620-PP-0108||Bombas verticales de Pozo Profundo|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Bomba de Pozo|LSTS2-0620-PP-PID-55401|\n\
0007||Bomba de Pozo|||Xylem||Sistema de Pozos|||4511554298||||4/1/2025|4/1/2025||||||||||||||56974301024|juan.troncoso@xylem.com|0620-PP-0109||Bombas verticales de Pozo Profundo|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Bomba de Pozo|LSTS2-0620-PP-PID-55401|\n\
0008||Bomba de Pozo|||Xylem||Sistema de Pozos|||4511554298||||4/1/2025|4/1/2025||||||||||||||56974301024|juan.troncoso@xylem.com|0620-PP-0110||Bombas verticales de Pozo Profundo|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Bomba de Pozo|LSTS2-0620-PP-PID-55401|\n\
0009||Bomba Vertical|||Neptuno||Sentina Colectora|||9100060389||||12/19/2023|12/19/2023||||||||||||||56572424685|info@neptuno.cl|0620-PP-0114||Bombas de Turbina Vertical|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Bomba Vertical|LSTS2-0620-PP-PID-55402|\n\
0010||Bomba Vertical|||Neptuno||Sentina Colectora|||9100060389||||12/19/2023|12/19/2023||||||||||||||56572424685|info@neptuno.cl|0620-PP-0115||Bombas de Turbina Vertical|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Bomba Vertical|LSTS2-0620-PP-PID-55402|\n\
0011||Subestación Unitaria|||Eaton||Área Booster 2 (Existente)|||9100060722||||3/7/2026|3/7/2026||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-US-001||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Subestación Unitaria|LSTS2-0620-EL-ESL-55101|\n\
0012||Reconectador|||ABB||Área Booster 2 (Existente)|||4512212004||||9/21/2025|9/21/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-SW-001||Reconectador|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Reconectador|LSTS2-0620-EL-ESL-55101|\n\
0013||Seccionador|||Eecol||Área Booster 2 (Existente)|||4512182368||||6/14/2025|6/14/2025||||||||||||||56226204200|c.vonoetinger@eecol.cl |0620-SW-002||Seccionador|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Seccionador|LSTS2-0620-EL-ESL-55101|\n\
0014||Transformador aéreo|||Eaton||Sistema de Pozos|||9100060722||||3/7/2026|3/7/2026||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-XF-001||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transformador aéreo|LSTS2-0620-EL-DSL-55401|\n\
0015||Tablero Dist.|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-DP-011||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Tablero Dist.|LSTS2-0620-EL-DSL-55401|0620-ER-001\n\
0016||Sala Eléctrica|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-ER-001||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Sala Eléctrica|LSTS2-0620-EL-GGA-55401|\n\
0017||Aire Acondicionado|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-ER-001-AC1||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Aire Acondicionado|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0018||Aire Acondicionado|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-ER-001-AC2||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Aire Acondicionado|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0019||Sist. Presurización|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-SDP-101||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Sist. Presurización|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0020||Sist. Presurización|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-SDP-102||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Sist. Presurización|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0021||Extinción de Incendios|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-ER-001-CI1||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Extinción de Incendios|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0022||Centro control de motores|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-MC-001||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Centro control de motores|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0023||Transformador seco|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-DX-001||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transformador seco|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0024||Transformador seco|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-LX-001||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transformador seco|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0025||Transformador seco|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-JX-001||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transformador seco|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0026||Tablero Dist.|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-DP-001||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Tablero Dist.|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0027||Tablero Dist.|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-LP-001||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Tablero Dist.|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0028||Tablero Dist.|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-DP-101||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Tablero Dist.|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0029||Fuente Ininterrumpida|||Eaton||Sistema de Pozos|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-UP-001||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Fuente Ininterrumpida|LSTS2-0620-EL-GGA-55401|0620-ER-001\n\
0030||Transformador aéreo|||Eaton||Sistema de Pozos|||9100060722||||3/7/2026|3/7/2026||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-XF-002||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transformador aéreo|LSTS2-0620-EL-DSL-55403|\n\
0031||Transformador aéreo|||Eaton||Sistema de Pozos|||9100060722||||3/7/2026|3/7/2026||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-XF-003||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transformador aéreo|LSTS2-0620-EL-DSL-55404|\n\
0032||Grupo Electrógeno|||Lureye||Piscina Colectora Drenajes|||4512004128||||6/14/2025|6/14/2025||||||||||||||56228975000|ruben.farias@lureye.cl |0620-GE-001||Generador de Emergencia|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Grupo Electrógeno|LSTS2-0620-EL-GGA-55301|\n\
0033||Tablero de transferencia|||Lureye||Piscina Colectora Drenajes|||4512004128||||6/14/2025|6/14/2025||||||||||||||56228975000|ruben.farias@lureye.cl |0620-TTA-001||Generador de Emergencia|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Tablero de transferencia|LSTS2-0620-EL-GGA-55301|0620-GE-001\n\
0034||Subestación Unitaria|||Eaton||Piscina Colectora Drenajes|||9100060722||||3/7/2026|3/7/2026||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-US-002||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Subestación Unitaria|LSTS2-0620-EL-GGA-55301|\n\
0037||Ducto de Barras|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-BD-001||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Ducto de Barras|LSTS2-0620-EL-GGA-55301|\n\
0038||Sala Eléctrica|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-ER-002||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Sala Eléctrica|LSTS2-0620-EL-GGA-55301|\n\
0039||Aire Acondicionado|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-ER-002-AC1||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Aire Acondicionado|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0040||Aire Acondicionado|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-ER-002-AC2||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Aire Acondicionado|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0041||Sistema Presurización|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-SDP-201||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Sistema Presurización|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0042||Sistema Presurización|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-SDP-202||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Sistema Presurización|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0043||Extinción Incendio|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-ER-002-CI1||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Extinción Incendio|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0044||Centro Control Motores|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-MC-002||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Centro Control Motores|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0045||Variador de Frecuencia|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-AF-001||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Variador de Frecuencia|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0046||Variador de Frecuencia|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-AF-002||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Variador de Frecuencia|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0047||Variador de Frecuencia|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-AF-003||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Variador de Frecuencia|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0048||Variador de Frecuencia|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-AF-004||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Variador de Frecuencia|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0049||Transformador Seco|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-DX-002||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transformador Seco|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0050||Transformador Seco|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-LX-002||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transformador Seco|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0051||Transformador Seco|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-JX-002||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transformador Seco|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0052||Tablero Dist. Fuerza|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-DP-002||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Tablero Dist. Fuerza|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0053||Tablero Dist. Alumbrado|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-LP-002||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Tablero Dist. Alumbrado|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0054||Tablero Dist. Fza. SSAA|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-DP-102||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Tablero Dist. Fza. SSAA|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0055||Fuente Ininterrumpida|||Eaton||Piscina Colectora Drenajes|||9100060722||||11/3/2025|11/3/2025||||||||||||||56957896513|rodrigoaguayo@eaton.com|0620-UP-002||Salas Electricas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Fuente Ininterrumpida|LSTS2-0620-EL-GGA-55301|0620-ER-002\n\
0056||Gabinete Fibra Optica|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-CU-001||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete Fibra Optica|LSTS2-0620-IC-UCB-55101/2|\n\
0057||Gabinete Fibra Optica|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-CU-002||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete Fibra Optica|LSTS2-0620-IC-UCB-55101/2|\n\
0058||Gabinete Fibra Optica|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-CU-004||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete Fibra Optica|LSTS2-0620-IC-UCB-55101/2|\n\
0059||Gabinete DCS|||ABB DCS||Sistema de Pozos|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-PC-004||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete DCS|LSTS2-0620-IC-IWC-55101|\n\
0060||Gabinete DCS|||ABB DCS||Piscina Colectora Drenajes|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-PC-001||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete DCS|LSTS2-0620-IC-IWC-55101|\n\
0061||Gabinete DCS|||ABB DCS||Sistema de Pozos|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-PC-002||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete DCS|LSTS2-0620-IC-IWC-55101|\n\
0062||Gabinete DCS|||ABB DCS||Sistema de Pozos|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-PC-003||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete DCS|LSTS2-0620-IC-IWC-55101|\n\
0063||Caja FDU|||ABB DCS||Sistema de Pozos|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-001||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0064||Caja FDU|||ABB DCS||Sistema de Pozos|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-002||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0065||Caja FDU|||ABB DCS||Sistema de Pozos|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-003||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0066||Caja FDU|||ABB DCS||Sistema de Pozos|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-004||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0067||Caja FDU|||ABB DCS||Sistema de Pozos|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-005||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0068||Caja FDU|||ABB DCS||Sistema de Pozos|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-006||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0069||Caja FDU|||ABB DCS||Sistema de Pozos|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-007||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0070||Caja FDU|||ABB DCS||Sentina Colectora Pozos|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-008||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0071||Caja FDU|||ABB DCS||Piscina Colectora Drenajes|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-009||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0072||Caja FDU|||ABB DCS||Piscina Colectora Drenajes|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-010||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0073||Caja FDU|||ABB DCS||Piscina Colectora Drenajes|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-011||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0074||Caja FDU|||ABB DCS||Piscina Colectora Drenajes|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-012||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0075||Caja FDU|||ABB DCS||Piscina Colectora Drenajes|||9100060921||||11/3/2025|11/3/2025||||||||||||||56999917201|ivan.villegas@cl.abb.com |0620-JB-013||DCS|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Caja FDU|LSTS2-0620-IC-IWC-55101|\n\
0076||Cámara CCTV Interior|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-IC-001||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Interior|LSTS2-0620-IC-DCD-55102|\n\
0077||Cámara CCTV Exerior|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-002||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55102|\n\
0078||Cámara CCTV Interior|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-IC-003||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Interior|LSTS2-0620-IC-DCD-55102|\n\
0079||Cámara CCTV Exerior|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-004||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55102|\n\
0080||Cámara CCTV Exerior|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-005||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55102|\n\
0081||Cámara CCTV Exerior|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-006||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55102|\n\
0082||Cámara CCTV Exerior|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-007||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55102|\n\
0083||Cámara CCTV Exerior|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-008||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55102|\n\
0084||Cámara CCTV Exerior|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-009||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55102|\n\
0085||Cámara CCTV Interior|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-IC-010||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Interior|LSTS2-0620-IC-DCD-55101|\n\
0086||Cámara CCTV Exerior|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-011||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55101|\n\
0087||Cámara CCTV Interior|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-IC-012||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Interior|LSTS2-0620-IC-DCD-55101|\n\
0088||Cámara CCTV Exerior|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-013||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55101|\n\
0089||Cámara CCTV Exerior|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-014||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55101|\n\
0090||Cámara CCTV Exerior|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-015||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55101|\n\
0091||Cámara CCTV Exerior|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-016||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55101|\n\
0092||Cámara CCTV Exerior|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-017||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55101|\n\
0093||Cámara CCTV Exerior|||Videocorp||Sentina Colectora Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-018||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior|LSTS2-0620-IC-DCD-55101|\n\
0094||Cámara CCTV Exerior |||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-OC-019||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Cámara CCTV Exerior |LSTS2-0620-IC-DBD-55101|\n\
0095||Gabinete CCTV|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-VP-020||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55101|\n\
0096||Gabinete CCTV|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-VP-021||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55102|\n\
0097||Gabinete CCTV|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-004||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55102|\n\
0098||Gabinete CCTV|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-005||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55102|\n\
0099||Gabinete CCTV|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-006||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55102|\n\
0100||Gabinete CCTV|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-007||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55102|\n\
0101||Gabinete CCTV|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-008||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55102|\n\
0102||Gabinete CCTV|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-009||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55102|\n\
0103||Gabinete CCTV|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-013||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55101|\n\
0104||Gabinete CCTV|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-014||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55101|\n\
0105||Gabinete CCTV|||Videocorp||Sistema de Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-015||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55101|\n\
0106||Gabinete CCTV|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-016||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55101|\n\
0107||Gabinete CCTV|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-017||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55101|\n\
0108||Gabinete CCTV|||Videocorp||Sentina Colectora Pozos|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-018||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55101|\n\
0109||Gabinete CCTV|||Videocorp||Piscina Colectora Drenajes|||4512458720||||5/22/2026|5/22/2026||||||||||||||56976968812|avergara@videocorp.com|0620-TP-019||Sist. Comunicaciones CCTV|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Gabinete CCTV|LSTS2-0620-IC-DCD-55101|\n\
0110||Transmisor de presión|||Endress+Hauser||Piscina Colectora Drenajes|||4511591422||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0101||Instrumentacion de Sentinas-Piscinas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55301|\n\
0111||Transmisor de presión|||Endress+Hauser||Piscina Colectora Drenajes|||4511591422||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0102||Instrumentacion de Sentinas-Piscinas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55301|\n\
0112||Transmisor de presión|||Endress+Hauser||Piscina Colectora Drenajes|||4511591422||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0103||Instrumentacion de Sentinas-Piscinas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55301|\n\
0113||Transmisor de presión|||Endress+Hauser||Piscina Colectora Drenajes|||4511591422||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0104||Instrumentacion de Sentinas-Piscinas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55301|\n\
0114||Transmisor de flujo magnético|||Endress+Hauser||Piscina Colectora Drenajes|||4511591422||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-FIT-0105||Instrumentacion de Sentinas-Piscinas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de flujo magnético|LSTS2-0620-PP-PID-55301|\n\
0115||Transmisor de nivel ultrasónico|||Endress+Hauser||Piscina Colectora Drenajes|||4511631910||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-LIT-0102||Medicion de Flujo Indirecta|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de nivel ultrasónico|LSTS2-0620-PP-PID-55301|\n\
0116||Analizador de Turbiedad|||Endress+Hauser||Piscina Colectora Drenajes|||4511872448||||2/7/2025|2/7/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-AIT-0102||Medidores de Turbidez|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Analizador de Turbiedad|LSTS2-0620-PP-PID-55301|\n\
0117||Transmisor de nivel ultrasónico|||Endress+Hauser||Piscina Colectora Drenajes|||4511631910||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-LIT-0103||Medicion de Flujo Indirecta|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de nivel ultrasónico|LSTS2-0620-PP-PID-55301|\n\
0118||Turbidez Cámara Medición de Caudal 0620-TK-0103|||Endress+Hauser||Piscina Colectora Drenajes|||4511872448||||2/7/2025|2/7/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-AIT-0103||Medidores de Turbidez|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Turbidez Cámara Medición de Caudal 0620-TK-0103|LSTS2-0620-PP-PID-55301|\n\
0119||Medición de nivel Cámara Medición de Caudal 0620-TK-0104|||Endress+Hauser||Piscina Colectora Drenajes|||4511631910||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-LIT-0104||Medicion de Flujo Indirecta|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Medición de nivel Cámara Medición de Caudal 0620-TK-0104|LSTS2-0620-PP-PID-55301|\n\
0120||Turbidez Cámara Medición de Caudal 0620-TK-0104|||Endress+Hauser||Piscina Colectora Drenajes|||4511872448||||2/7/2025|2/7/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-AIT-0104||Medidores de Turbidez|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Turbidez Cámara Medición de Caudal 0620-TK-0104|LSTS2-0620-PP-PID-55301|\n\
0121||Medición de nivel Cámara Medición de Caudal 0620-TK-0106|||Endress+Hauser||Piscina Colectora Drenajes|||4511631910||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-LIT-0106||Medicion de Flujo Indirecta|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Medición de nivel Cámara Medición de Caudal 0620-TK-0106|LSTS2-0620-PP-PID-55301|\n\
0122||Turbidez Cámara Medición de Caudal 0620-TK-0106|||Endress+Hauser||Piscina Colectora Drenajes|||4511872448||||2/7/2025|2/7/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-AIT-106||Medidores de Turbidez|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Turbidez Cámara Medición de Caudal 0620-TK-0106|LSTS2-0620-PP-PID-55301|\n\
0123||Medición de nivel piscina colectora de drenaje|||Endress+Hauser||Piscina Colectora Drenajes|||4511591422||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-LIT-0108||Instrumentacion de Sentinas-Piscinas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Medición de nivel piscina colectora de drenaje|LSTS2-0620-PP-PID-55301|\n\
0124||Medición de nivel sentina colectora de pozos|||Endress+Hauser||Sentina Colectora Pozos|||4511591422||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-LIT-0116||Instrumentacion de Sentinas-Piscinas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Medición de nivel sentina colectora de pozos|LSTS2-0620-PP-PID-55402|\n\
0125||Transmisor de presión|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0106||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55401|\n\
0126||Transmisor de flujo magnético|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-FIT-0106||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de flujo magnético|LSTS2-0620-PP-PID-55401|\n\
0127||Transmisor de presión|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0107||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55401|\n\
0128||Transmisor de flujo magnético|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-FIT-0107||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de flujo magnético|LSTS2-0620-PP-PID-55401|\n\
0129||Transmisor de presión|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0108||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55401|\n\
0130||Transmisor de flujo magnético|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-FIT-0108||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de flujo magnético|LSTS2-0620-PP-PID-55401|\n\
0131||Transmisor de presión|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0109||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55401|\n\
0132||Transmisor de flujo magnético|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-FIT-0109||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de flujo magnético|LSTS2-0620-PP-PID-55401|\n\
0133||Transmisor de presión|||Endress+Hauser||Sentina Colectora Pozos|||4511591422||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0113||Instrumentacion de Sentinas-Piscinas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55402|\n\
0134||Transmisor de presión|||Endress+Hauser||Sentina Colectora Pozos|||4511591422||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0114||Instrumentacion de Sentinas-Piscinas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55402|\n\
0135||Transmisor de flujo magnético|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-FIT-0110||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de flujo magnético|LSTS2-0620-PP-PID-55401|\n\
0136||Transmisor de presión|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0110||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55401|\n\
0137||Transmisor de flujo magnético|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-FIT-0111||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de flujo magnético|LSTS2-0620-PP-PID-55401|\n\
0138||Transmisor de presión|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0111||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55401|\n\
0139||Transmisor de flujo magnético|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-FIT-0112||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de flujo magnético|LSTS2-0620-PP-PID-55401|\n\
0140||Transmisor de presión|||Endress+Hauser||Sistema de Pozos|||4511455105||||8/9/2023|8/9/2023||||||||||||||56934214550|elson.millanir@endress.com|0620-PIT-0112||Instrumentacion de Pozos|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de presión|LSTS2-0620-PP-PID-55401|\n\
0141||Transmisor de flujo magnético|||Endress+Hauser||Sentina Colectora Pozos|||4511591422||||1/24/2025|1/24/2025||||||||||||||56934214550|elson.millanir@endress.com|0620-FIT-0115||Instrumentacion de Sentinas-Piscinas|Cristian Barra|cristian.barra1@bhp.com|LSTS WP03|Transmisor de flujo magnético|LSTS2-0620-PP-PID-55402|';

//Identificador Transelec|Número de serie|Nombre del Equipo|Descripción del Equipo|Fabricante|Proveedor|Modelo Equipo|Ubicación|Área Responsable|Comentarios (tension kV)|Identificador contrato|Año fabricacion|Fecha de PES|Meses|Fecha garantía hasta|Expiración Garantía|Fotos Equipo|Manuales Técnicos|Documentos administrativos|Categoría Equipo|Criticidad|tipo|subtipo|codigoEstructura|ID2|Codigo SAP|Condiciones especiales expiracion garantía|Rut proveedor|Contacto proveedor|fono proveedor|email proveedor|TAG|ADC|Contrato|Ingeniero Contrato|Mail Ingeniero Contrato|Proyecto|Nombre Corto
// let datos =
// '\
// 000001||Pararrayos 69 Kv Línea 69 kV- Fase C|Pararrayos 69 Kv Línea 69 kV- Fase C||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2023|10/31/2023|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0762-VL-001C||HV|||Proyecto LSTS WP02|Pararrayos 69 Kv Línea 69 kV- Fase C\n\
// 000002||Resistencia de Neutro 4,16 kV|Resistencia de Neutro 4,16 kV||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0762-NGR-101||HV|||Proyecto LSTS WP02|Resistencia de Neutro 4,16 kV\n\
// 000003||Armario Merge Units N° 2|Armario Merge Units N° 2||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||11/31/2023|11/31/2023|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0690-C0-001||HV|||Proyecto LSTS WP02|Armario Merge Units N° 2\n\
// 000004||Merging Unit N°1 - Trafo|Merging Unit N°1 - Trafo||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||09/31/2023|09/31/2023|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0690-FQC-001||HV|||Proyecto LSTS WP02|Merging Unit N°1 - Trafo\n\
// 000005||Gabinete de Control Local GIS|Gabinete de Control Local GIS||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0762-C0-001||HV|||Proyecto LSTS WP02|Gabinete de Control Local GIS\n\
// 000006||Transformador de corriente GIS|Transformador de corriente GIS||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0762-XF-003||HV|||Proyecto LSTS WP02|Transformador de corriente GIS\n\
// 000007||Desconectador de puesta a tierra rápido N° 2|Desconectador de puesta a tierra rápido N° 2||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0762-ESD-002||HV|||Proyecto LSTS WP02|Desconectador puesta a tierra rápido N° 2\n\
// 000008||Interruptor de Poder|Interruptor de Poder||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0762-CB-001||HV|||Proyecto LSTS WP02|Interruptor de Poder\n\
// 000009||Armario Merge Units N° 1|Armario Merge Units N° 1||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0690-C0-006||HV|||Proyecto LSTS WP02|Armario Merge Units N° 1\n\
// 000010||Concentrador de Datos RTU - N°1|Concentrador de Datos RTU - N°1||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0690-RTU-001||HV|||Proyecto LSTS WP02|Concentrador de Datos RTU - N°1\n\
// 000011||HMI|HMI||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0690-ECS-001||HV|||Proyecto LSTS WP02|HMI\n\
// 000012||Switch RST 2228 - Bus de Estación N°2|Switch RST 2228 - Bus de Estación N°2||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0690-NS-003||HV|||Proyecto LSTS WP02|Switch RST 2228 - Bus de Estación N°2\n\
// 000013||Router RX1500 N°1|Router RX1500 N°1||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0690-NS-006||HV|||Proyecto LSTS WP02|Router RX1500 N°1\n\
// 000014||Redbox N°2|Redbox N°2||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0690-NS-009||HV|||Proyecto LSTS WP02|Redbox N°2\n\
// 000015||ODF-1 / OPGW|ODF-1 / OPGW||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0620-FPP-001||HV|||Proyecto LSTS WP02|ODF-1 / OPGW\n\
// 000016||Tablero de Distribución 380/220 VAC -Esencial |Tablero de Distribución 380/220 VAC -Esencial ||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0762-PDP-004||HV|||Proyecto LSTS WP02|Tablero Distribución 380/220 VAC -Esencial \n\
// 000017||Cargador de Baterías N° 1 400 VAC / 125 VDC|Cargador de Baterías N° 1 400 VAC / 125 VDC||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0762-BAT-001||HV|||Proyecto LSTS WP02|Cargador Baterías N° 1 400 VAC / 125 VDC\n\
// 000018||Panel Sistema de Detección y Control  Incendio|Panel Sistema de Detección y Control  Incendio||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0762-FP-002||HV|||Proyecto LSTS WP02|Panel Sistema Detección y Control  Incendio\n\
// 000019||Tablero de Transferencia Automática|Tablero de Transferencia Automática||Sigdo Koppers||Subestación Colorado Chico|||9100059446||||10/31/2024|10/31/2024|||||||||||||Ricardo Thumala |+56 9 92130905|ricardo.thumala@skic.com|0762-ATS-001||HV|||Proyecto LSTS WP02|Tablero de Transferencia Automática\n\
// 000020||Transformador Seco para Alumbrado 25KVA |Transformador Seco para Alumbrado 25KVA – 0.4/0.4-0.231kV, 50Hz.||Techint ||Sala eléctrica tipo Contenedor 0620-ER-101|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-LX-101||TTS|||Proyecto LSTS WP02|Transformador Seco Alumbrado 25KVA \n\
// 000021||Tablero de Servicios Auxiliares |Tablero de Servicios Auxiliares Sala Eléctrica (Alimentado desde 0620-DP-101A)   NEMA 12, 400-231 V, 50 Hz, 3F+N+T, Icc 15 kA.||Techint ||Sala eléctrica tipo Contenedor 0620-ER-101|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-DP-104A||TTS|||Proyecto LSTS WP02|Tablero de Servicios Auxiliares \n\
// 000022||Ducto de Barras Media Tensión|Ducto de Barras Media Tensión  4160V, 3000 A, 50Hz||Techint ||Sala eléctrica tipo Contenedor 0620-ER-101|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-BD-101||TTS|||Proyecto LSTS WP02|Ducto de Barras Media Tensión\n\
// 000023||Tablero alimentación válvulas motorizadas|Tablero alimentación válvulas motorizadas, barras de cobre, voltaje nominal 400-231V, 3F+N+T, In 100A.||Techint ||Sala eléctrica tipo Contenedor 0620-ER-101|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-DP-106||TTS|||Proyecto LSTS WP02|Tablero alimentación válvulas motorizadas\n\
// 000024||Transformador de distribución 1000kVA |Transformador de distribución 1000kVA – 0.4/0.4-0.231kV, 50Hz||Techint ||Sala eléctrica tipo Contenedor 0620-ER-101|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-XF-102||TTS|||Proyecto LSTS WP02|Transformador de distribución 1000kVA \n\
// 000025||Transformador Seco 100kVA|Transformador Seco para Fuerza y SSAA 100kVA – 0.4/0.4-0.231kV, 50Hz.||Techint ||Sala eléctrica tipo Contenedor 0620-ER-102|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-DX-101B||TTS|||Proyecto LSTS WP02|Transformador Seco 100kVA\n\
// 000026||Panel Sistema de Detección/Extinción de Incendio|Panel Sistema de Detección/Extinción de Incendio||Techint ||Sala eléctrica tipo Contenedor 0620-ER-102|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-ER-102-FP1||TTS|||Proyecto LSTS WP02|Panel Sistema de Detección/Extinción de Incendio\n\
// 000027||Sistema de Climatización 380V, Sala 0620-ER-102|Sistema de Climatización 380 V (Aire Acondicionado) Sala 0620-ER-102||Techint ||Sala eléctrica tipo Contenedor 0620-ER-102|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-ER-102-AC1||TTS|||Proyecto LSTS WP02|Sistema de Climatización 380V, Sala 0620-ER-102\n\
// 000028||Sistema de Presurización 380V, Sala 0620-ER-102|Sistema de Presurización 380V, 50Hz. - Sala 0620-ER-102||Techint ||Sala eléctrica tipo Contenedor 0620-ER-102|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-SDP-102A||TTS|||Proyecto LSTS WP02|Sistema de Presurización 380V, Sala 0620-ER-102\n\
// 000029||Transformador Aislación Para VDF MT|TRANSFORMADOR DE AISLACIÓN PARA VARIADOR DE FRECUENCIA MEDIA TENSION 0620-AF-101, 4,16 / 4x2,3 kV.||Techint ||Sala eléctrica tipo Contenedor 0620-ER-102|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-AF-101XF||TTS|||Proyecto LSTS WP02|Transformador Aislación Para VDF MT\n\
// 000030||Sensor -Transmisor de Nivel Radar|Sensor -Transmisor de Nivel Radar||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-LT-0201||TTS|||Proyecto LSTS WP02|Sensor -Transmisor de Nivel Radar\n\
// 000031||Indicador de Nivel|Indicador de Nivel||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-LI-0201||TTS|||Proyecto LSTS WP02|Indicador de Nivel\n\
// 000032||Sensor -Interruptor de Nivel tipo Horquilla Vibrante |Sensor -Interruptor de Nivel tipo Horquilla Vibrante ||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-LSLL-0201||TTS|||Proyecto LSTS WP02|Sensor-Interruptor Nivel tipo Horquilla Vibrante \n\
// 000033||Sensor de Flujo tipo Sonar|Sensor de Flujo tipo Sonar||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-FE-0201||TTS|||Proyecto LSTS WP02|Sensor de Flujo tipo Sonar\n\
// 000034||Transmisor de Flujo tipo Sonar|Transmisor de Flujo tipo Sonar||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-FIT-0201||TTS|||Proyecto LSTS WP02|Transmisor de Flujo tipo Sonar\n\
// 000035||Sensor -Transmisor de Presión con capilar y Sello Diafragma|Sensor -Transmisor de Presión con capilar y Sello Diafragma||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-PIT-0201||TTS|||Proyecto LSTS WP02|Sensor-Transmisor Presión con capilar y Sello Diafragma\n\
// 000036||Válvula de Bloqueo Mariposa con Actuador Motorizado|Válvula de Bloqueo Mariposa con Actuador Motorizado||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-XV-0204||TTS|||Proyecto LSTS WP02|Válvula Bloqueo Mariposa con Actuador Motorizado\n\
// 000037||Válvula de Bloqueo Bola con Actuador Motorizado|Válvula de Bloqueo Bola con Actuador Motorizado||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-XV-0217||TTS|||Proyecto LSTS WP02|Válvula Bloqueo Bola con Actuador Motorizado\n\
// 000038||Válvula de Bloqueo Cuchilla con Actuador Motorizado|Válvula de Bloqueo Cuchilla con Actuador Motorizado||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-XV-0250||TTS|||Proyecto LSTS WP02|Válvula Bloqueo Cuchilla con Actuador Motorizado\n\
// 000039||Válvula de Bloqueo Cuchilla con Actuador Motorizado|Válvula de Bloqueo Cuchilla con Actuador Motorizado||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-XV-0273||TTS|||Proyecto LSTS WP02|Válvula de Bloqueo Cuchilla con Actuador Motorizado\n\
// 000040||Válvula de Bloqueo con Actuador Hidráulico|Válvula de Bloqueo con Actuador Hidráulico||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-XV-0287||TTS|||Proyecto LSTS WP02|Válvula Bloqueo con Actuador Hidráulico\n\
// 000041||Válvula Pinch con Actuador Hidráulico|Válvula Pinch con Actuador Hidráulico||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-HV-0300||TTS|||Proyecto LSTS WP02|Válvula Pinch con Actuador Hidráulico\n\
// 000042||Válvula Autorreguladora|Válvula Autorreguladora||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-PCV-0227||TTS|||Proyecto LSTS WP02|Válvula Autorreguladora\n\
// 000043||Válvula Ventosa|Válvula Ventosa||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-FSV-0318||TTS|||Proyecto LSTS WP02|Válvula Ventosa\n\
// 000044||Bomba Centrífuga Horizontal - Relaves    |Bomba Centrífuga Horizontal - Relaves Bomba Centrífuga de Pulpa  HI 12.1 - 12.6  ||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-PP-0201||TTS|||Proyecto LSTS WP02|Bomba Centrífuga Horizontal - Relaves \n\
// 000045||Bomba Centrífuga Horizontal - Relaves    |Bomba Centrífuga Horizontal - Relaves Bomba Centrífuga de Pulpa  HI 12.1 - 12.6  ||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-PP-0202||TTS|||Proyecto LSTS WP02|Bomba Centrífuga Horizontal - Relaves \n\
// 000046||Bomba Centrífuga Horizontal - Relaves    |Bomba Centrífuga Horizontal - Relaves Bomba Centrífuga de Pulpa  HI 12.1 - 12.6  ||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-PP-0203||TTS|||Proyecto LSTS WP02|Bomba Centrífuga Horizontal - Relaves \n\
// 000047||Bomba Centrífuga Horizontal - Relaves    |Bomba Centrífuga Horizontal - Relaves Bomba Centrífuga de Pulpa  HI 12.1 - 12.6  ||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-PP-0204||TTS|||Proyecto LSTS WP02|Bomba Centrífuga Horizontal - Relaves\n\
// 000048||Bomba Sumergible de Lodos - Relaves  |Bomba Sumergible de Lodos - Relaves Bomba Sumergible Centrífuga Vertical  ||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-PP-0205||TTS|||Proyecto LSTS WP02|Bomba Sumergible de Lodos - Relaves\n\
// 000049||Bomba Centrífuga Horizontal - Agua de Lavado|Bomba Centrífuga Horizontal - Agua de Lavado||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-PP-0206||TTS|||Proyecto LSTS WP02|Bomba Centrífuga Horizontal - Agua de Lavado\n\
// 000050||Bomba Desplazamiento Positivo - Agua de Sellos|Bomba de Desplazamiento Positivo Tipo Pistón - Agua de Sellos||Techint ||Estación de impulsión TTS|||9100059914||||11/30/2025|11/30/2025|||||||||||||Allan Piscoya|+56 9 92130906|apiscoya@techint.com.pe|0620-PP-0208||TTS|||Proyecto LSTS WP02|Bomba Desplazamiento Positivo - Agua de Sellos';

//Identificador Transelec|Número de serie|Nombre del Equipo|Descripción del Equipo|Fabricante|Proveedor|Modelo Equipo|Ubicación|Área Responsable|Comentarios (tension kV)|Identificador contrato|Año fabricacion|Fecha de PES|Meses|Fecha garantía hasta|Expiración Garantía|Fotos Equipo|Manuales Técnicos|Documentos administrativos|Categoría Equipo|Criticidad|tipo|subtipo|codigoEstructura|ID2|Codigo SAP|Condiciones especiales expiracion garantía|Rut proveedor|Contacto proveedor|fono proveedor|email proveedor|TAG|ADC
// let datos =
// '\
// 0000001||PE004-MOTOR DE MOLINOS-PRI|Incluye: Planos y documentos Vendor de acuerdo. 1 UN Motor Síncrono Horizontal, 800 HP. 4 UN Motor Síncrono Horizontal, 1250 HP. 8 UN Pruebas FAT y otros ensayos requerid Lubricantes Repuestos para el Comisionamiento. 4 GL (para cojinete y para aire) Repuestos para la Puesta en Marcha 2 GL  (para cojinete y para aire) Repuestos Capitalizables. Herramientas Especiales. (1250 - 800 HP) ||WEG CHILE S.A.|||||4600020418||5/25/2022|||11/25/2023|||||||||||Boleta de fiel cumplimiento de la orden de compra: desde 22-06-2021 hasta 25-07-2022|99528050-7|Edson Niitsuma|2 2784 8900|niitsuma@weg.net|03210-MBA-003M|Sin información\n\
// 0000002||Correas Transporte Intermedio y Correas Colectoras Norte y Sur para MB´s N41/42 y S04|Incluye: Electroimán del Alimentador de Orugas. 1 UN Electroimán Correa Intermedia. 1 UN. Embalaje estándar del prov. Huinche del Tensor Correa Colectora Norte.1 UN Desconectador de Seguridad. 1 UN RTD para poleas Correa Colectora Norte 2. 1 UN Huinche Tensor Correa Intermedia Motor correa Intermedia 150 kW. 1 UN Huinche Tensor Correa Intermedia Desconectador de Seguridad.  Chute Descarga Correa Intermedia. RTD para poleas Correa Intermedia 223462. Electroimán del Alimentador de Orugas. Electroimán Correa Intermedia (descarga). Motor Correa Coelctora , Potencia 1.400k. 1 UN Huinche del Tensor Correa Colectora Nort. RTD para poleas Correa Colectora Sur 20212. Chute Descarga Correa Colectora Sur. Repuestos Capitalizables, 150kW. Repuestos Capitalizables, 1400kW Repuestos Capitalizables, Drive Pulley. Repuestos Capitalizables, Take-up Pulley Repuestos Capitalizables, High Tension Repuestos Capitalizables Bearing and Sleeve (Pair). Repuestos Capitalizables, Belt EP 1000. Repuestos Capitalizables, Belt St 1250. Repuestos Capitalizables, Spared motors. Repuestos Puesta en Marcha, Small Parts. Repuestos Puesta en Marcha, Impact Roll. Repuestos Puesta en Marcha, Carry Roll. Repuestos Puesta en Marcha, Return Roll. Repuestos Puesta en Marcha, Carry Frame. Repuestos Puesta en Marcha, V-Return Fra Repuestos Puesta en Marcha, Pulleys RTD Repuestos Puesta en Marcha, Belt Scale Repuestos Puesta en Marcha, Magnet.||TAKRAF CHILE SPA|||||4600021932||2/24/2022|||1/20/2023|||||||||||Dieciocho (18) meses desde el Término Mecánico o Veinticuatro (24) meses desde la entrega en condición pactada, lo que ocurra primero.|76530760-0|Christian Cavagnaro Michell |2 23983000|christian.cavagnaro@takraf.com|TAG 222462-CTR-001-01-SW 480 V, 63 A, DCH|Sin información\n\
// 0000003||SALA DE CHANCADO ÓXIDOS|Incluye: CHANCADOR PRIMARIO. 1 UN Estr prin acero est chan-soportes. 1 UN. Estr prin acero est chan-Tolv alim tolv. 1 UN Estr prin acero est chan-Puente acceso c. 1 UN Escaleras, Barandas, pasillos. 1 UN Estr acero soporte cubierta encer. 1 UN Tap, porton mate cub. 1 UN Talhas Elect Mante. 1 UN BRAZO MULT 1UN CONT POLVO CHAN. 1UN  GRÚA PED. 1UN CARRO MAN. 1UN INSTRUM, SIST CONT SALA CONT. 1UN APRON FEEDER. 1UN DETECT MET ELECT. 1UN CONT POLV CORREA. 1UN EST TRANSF CORREA TRASP (Chute 1). CONT POLV EST TRAN (Chute 1). CORREA TRAS (120 METROS). EST TRANS CORREA OVER (Chute 2) CONT POL EST TRANS (Chute 2). SIST CONT INCEN Sala eléctrica Paneles elec, VFD. Transformadores. CCTV, 11 cám Chan cont rem autolim. Sist Cont Acces salas eléct. Intrumentacion y Control. Alim eléct sala. Sist Dteccion corte correa. Cinta Overlan. PESÓMETRO (CORREA OVERLAND). SISTEMA CONTRA INCENDIO SERVICIOS SALA CHANCA Repuestos Puesta en Marcha, Belt Scale Repuestos Puesta en Marcha, Magnet.||METSO OUTOTEC CHILE SPA||División Radomiro Tomic.|||4600020869||Fecha de entrega: al momento de vencer la Boleta de Garantía por Fiel cumplimiento|||veinticuatro (24) meses desde la Puesta en Marcha o treinta y seis (36) meses desde la entrega en el lugar pactado, lo que ocurra primero.|||||||||||boleta de garantía de calidad y buen funcionamiento, fecha estimada para el mes marzo del 2023.|93077000-0|Juan Collao |9 3241 1755|juan.collao@mogroup.com||Sin información\n\
// 0000004||VENTILADORES SECUNDARIOS|Incluye: 1era entrega 24 ventiladores. 11VDF y 1 PLC 2da entrega 12 ventiladores. 12 VDF y 3 PLC 3era entrega 11 ventiladores. 11VDF, 3 PLC y repuestos. ||Zitron Chile SpA||DMH|||4600022445||Desde el término de entrega del último bien y/o suministro, hasta 12 meses de la PEM del sistema.|||12 meses|||||||||||boleta de garantía por Calidad y Buen Funcionamiento|76.900.150-6|Avelino Diaz Cadierno|9 6837 4546|adiaz@zitron.com||Sin información\n\
// 0000004||SISTEMA RADIOCOMUNICACIONES DAS |Incluye: Gabinete Radiocomunicaciones en Sala. 1 UN Gabinete Radiocomunicaciones en en Sitio. 1 UN. Gabinete Radiocomunicaciones en Sala Rec. 1 UN Tablero de RadiocomunicacionesNivel. 2 UN Tablero de RadiocomunicacionesNivel. 9 UN Tablero de RadiocomunicacionesNivel de T. 11 UN Tablero de RadiocomunicacionesSubNivel d. 6 UN Tablero de RadiocomunicacionesNivel Teni. 3 UN Tablero de RadiocomunicacionesAdit 77. 3UN Tablero de RadiocomunicacionesBarrio Cív. 2UN  Unidad RemotaNivel Hundimiento. 2 UN Unidad RemotaNivel Producción. 10 UN Unidad RemotaNivel de Transporte Interme. 10 UN Unidad RemotaSubNivel de Ventilación. 11 UN Unidad RemotaNivel Teniente 8.  4 UN Unidad RemotaAdit 77. 3 UN Unidad RemotaBarrio Cívico y Talleres. 2 UN Fibra Óptica Monomodo 12 Filamentos. 27.000,00 metro Fibra Óptica Monomodo 24 Filamentos. 13.000 mts Cable Radiante 7/8#Ver. 24.000 mts Cable coaxial 1/2". mil metros SIST CONT INCEN ||VIGATEC S A||DET|||4600022102||10/6/2022|||11/5/2025|||||||||||Garantía de Calidad y Funcionamiento|96587380-5|Giorgio Cuneo L.|2 2350 7000|gcuneo@vigatec.cl||Sin información';

//Identificador Transelec,Número de serie,Nombre del Equipo,Descripción del Equipo,Fabricante,Proveedor,Modelo Equipo,Ubicación,Área Responsable,Comentarios (tension kV),Identificador contrato,Año fabricacion,Fecha de PES,Meses,Fecha garantía hasta,Expiración Garantía,Fotos Equipo,Manuales Técnicos,Documentos administrativos,Categoría Equipo ,Criticidad, tipo, subtipo, codigoEstructura, ID2
// let datos =
// '\
// 0000001,,PE004-MOTOR DE MOLINOS-PRI,,,WEG CHILE S.A. / 99528050-7,,03210-MBA-003M,,,Edson Niitsuma/2 2784 8900/niitsuma@weg.net,,5/25/2022,,,11/25/2023,,,,,,,,,,4600020418,Boleta de fiel cumplimiento de la orden de compra: desde 22-06-2021 hasta 25-07-2022\n\
// 0000002,,"Correas Transporte Intermedio y Correas Colectoras Norte y Sur para MB´s N41/42 y S04",,,TAKRAF CHILE SPA / 76530760-0,,"TAG 222462-CTR-001-01-SW 480 V, 63 A, DCH",,,Christian Cavagnaro Michell / 2 23983000 / christian.cavagnaro@takraf.com,,2/24/2022,,,1/20/2023,,,,,,,,,,4600021932,"Dieciocho (18) meses desde el Término Mecánico o Veinticuatro (24) meses desde la entrega en condición pactada, lo que ocurra primero."\n\
// 0000003,,SALA DE CHANCADO ÓXIDOS,,,METSO OUTOTEC CHILE SPA / 93077000-0,,División Radomiro Tomic.,,,Juan Collao / 9 3241 1755 / juan.collao@mogroup.com,,Fecha de entrega: al momento de vencer la Boleta de Garantía por Fiel cumplimiento,,,"veinticuatro (24) meses desde la Puesta en Marcha o treinta y seis (36) meses desde la entrega en el lugar pactado, lo que ocurra primero.",,,,,,,,,,4600020869,"boleta de garantía de calidad y buen funcionamiento,fecha estimada para el mes marzo del 2023."\n\
// 0000004,,VENTILADORES SECUNDARIOS,,,Zitron Chile SpA / 76.900.150-6,,DMH,,,Avelino Diaz Cadierno / 9 6837 4546 / adiaz@zitron.com,,"Desde el término de entrega del último bien y/o suministro, hasta 12 meses de la PEM del sistema.",,,12 meses,,,,,,,,,,4600022445,boleta de garantía por Calidad y Buen Funcionamiento\n\
// 0000005,,SISTEMA RADIOCOMUNICACIONES DAS ,,,VIGATEC S A / 96587380-5,,DET,,,Giorgio Cuneo L. / 2 2350 7000 / gcuneo@vigatec.cl,,10/6/2022,,,11/5/2025,,,,,,,,,,4600022102,Garantía de Calidad y Funcionamiento';

// 00000001,Pararrayo: ACD P220 J4: ES01-PRJ4-1 F1,Pararrayo: ACD P220 J4: ES01-PRJ4-1 F1,Siemens,Siemens,3EP6,S/E Ancud,Subestación,198,PARA-1541_Pararrayo,2020,8/1/2021,18,1/23/2023,,,,,Equipo de patio,,Pararrayo,ACD P220 J4,ES01-PRJ4-1 F1,PR J4 FASE 1 CHILOÉ 1\n\
// 00000002,K/35334164,Pararrayo: ACD P220 J4: ES01-PRJ4-2 F2,Pararrayo: ACD P220 J4: ES01-PRJ4-2 F2,Siemens,Siemens,3EP6,S/E Ancud,Subestación,198,PARA-1542_Pararrayo,2020,8/1/2021,18,1/23/2023,,,,,Equipo de patio,,Pararrayo,ACD P220 J4,ES01-PRJ4-2 F2,PR J4 FASE 2 CHILOÉ 1\n\
// PRJ4-3 F3,K/35334165,Pararrayo: ACD P220 J4: ES01-PRJ4-3 F3,Pararrayo: ACD P220 J4: ES01-PRJ4-3 F3,Siemens,Siemens,3EP6,S/E Ancud,Subestación,198,PARA-1543_Pararrayo,2020,8/1/2021,18,1/23/2023,,,,,Equipo de patio,,Pararrayo,ACD P220 J4,ES01-PRJ4-3 F3,PR J4 FASE 3 CHILOÉ 1\n\
// PRJ7-1 F1,K/35334166,Pararrayo: ACD P220 J7: ES01-PRJ7-1 F1,Pararrayo: ACD P220 J7: ES01-PRJ7-1 F1,Siemens,Siemens,3EP6,S/E Ancud,Subestación,198,PARA-1544_Pararrayo,2020,8/1/2021,18,1/23/2023,,,,,Equipo de patio,,Pararrayo,ACD P220 J7,ES01-PRJ7-1 F1,PR J7 FASE 1 PARGUA 1\n\
// PRJ7-2 F2,K/35334167,Pararrayo: ACD P220 J7: ES01-PRJ7-2 F2,Pararrayo: ACD P220 J7: ES01-PRJ7-2 F2,Siemens,Siemens,3EP6,S/E Ancud,Subestación,198,PARA-1545_Pararrayo,2020,8/1/2021,18,1/23/2023,,,,,Equipo de patio,,Pararrayo,ACD P220 J7,ES01-PRJ7-2 F2,PR J7 FASE 2 PARGUA 1\n\
// PRJ7-3 F3,K/35334168,Pararrayo: ACD P220 J7: ES01-PRJ7-3 F3,Pararrayo: ACD P220 J7: ES01-PRJ7-3 F3,Siemens,Siemens,3EP6,S/E Ancud,Subestación,198,PARA-1546_Pararrayo,2020,8/1/2021,18,1/23/2023,,,,,Equipo de patio,,Pararrayo,ACD P220 J7,ES01-PRJ7-3 F3,PR J7 FASE 3 PARGUA 1\n\
// TCJ7-2 t.b-F2,TTCC J7 PARGUA 1,Transformador Corriente: ACD P220  J7:  TCJ7-2 t.b-F2,Transformador Corriente: ACD P220  J7:  TCJ7-2 t.b-F2,Sieyuan,Sieyuan,SSTA01,S/E Ancud,Subestación,220,TC-3463_Transformadores de corriente,2020,8/1/2021,18,1/23/2023,,,,,Equipo de patio,,Transformador Corriente,ACD P220  J7,TCJ7-2 t.b-F2,TTCC J7 PARGUA 1\n\
// TCJ9-1 t.b-F2,TTCC J9 TINEO 1,Transformador Corriente: ACD P220  J9:  TCJ9-1 t.b-F2,Transformador Corriente: ACD P220  J9:  TCJ9-1 t.b-F2,Sieyuan,Sieyuan,SSTA01,S/E Ancud,Subestación,220,TC-3472_Transformadores de corriente,2020,8/1/2021,18,1/23/2023,,,,,Equipo de patio,,Transformador Corriente,ACD P220  J9,TCJ9-1 t.b-F2,TTCC J9 TINEO 1\n\
// TCJ9-1 t.b-F3,TTCC J9 TINEO 1,Transformador Corriente: ACD P220  J9:  TCJ9-1 t.b-F3,Transformador Corriente: ACD P220  J9:  TCJ9-1 t.b-F3,Sieyuan,Sieyuan,SSTA01,S/E Ancud,Subestación,220,TC-3473_Transformadores de corriente,2020,8/1/2021,18,1/23/2023,,,,,Equipo de patio,,Transformador Corriente,ACD P220  J9,TCJ9-1 t.b-F3,TTCC J9 TINEO 1\n\
// TCJ9-2 t.b-F1,TTCC J9 TINEO 1,Transformador Corriente: ACD P220  J9:  TCJ9-2 t.b-F1,Transformador Corriente: ACD P220  J9:  TCJ9-2 t.b-F1,Sieyuan,Sieyuan,SSTA01,S/E Ancud,Subestación,220,TC-3474_Transformadores de corriente,2020,8/1/2021,18,1/23/2023,,,,,Equipo de patio,,Transformador Corriente,ACD P220  J9,TCJ9-2 t.b-F1,TTCC J9 TINEO 1\n\
// TCJ9-2 t.b-F2,TTCC J9 TINEO 1,Transformador Corriente: ACD P220  J9:  TCJ9-2 t.b-F2,Transformador Corriente: ACD P220  J9:  TCJ9-2 t.b-F2,Sieyuan,Sieyuan,SSTA01,S/E Ancud,Subestación,220,TC-3475_Transformadores de corriente,2020,8/1/2021,18,1/23/2023,,,,,Equipo de patio,,Transformador Corriente,ACD P220  J9,TCJ9-2 t.b-F2,TTCC J9 TINEO 1\n\
// TCJ9-2 t.b-F3,TTCC J9 TINEO 1,Transformador Corriente: ACD P220  J9:  TCJ9-2 t.b-F3,Transformador Corriente: ACD P220  J9:  TCJ9-2 t.b-F3,Sieyuan,Sieyuan,SSTA01,S/E Ancud,Subestación,220,TC-3476_Transformadores de corriente,2020,8/1/2021,18,1/23/2023,,,,,Equipo de patio,,Transformador Corriente,ACD P220  J9,TCJ9-2 t.b-F3,TTCC J9 TINEO 1';


// PE004-MOTOR DE MOLINOS-PRI
// Correas Transporte Intermedio y Correas Colectoras Norte y Sur para MB´s N41/42 y S04
// SALA DE CHANCADO ÓXIDOS
// VENTILADORES SECUNDARIOS
// SISTEMA RADIOCOMUNICACIONES DAS 

      datos = ''; //clear

      let lines = datos.split("\n");
      // console.log(" ***** " + lines.length);
      let i = 0;
      lines.forEach(line => {
        // console.log(++i + ":\n " + line);
        // let atributos = line.split(",");
        let atributos = line.split("|");
        // console.log("atributos: %s %s", atributos[0], atributos[1]);
        if (atributos.length >= 25) {
          // var newPost = firebase.database().ref("inventario/EscondidaLSTSWP03").push();
          var newPost = firebase.database().ref(this.db_ref_inventario).push();

          let newElement = {
            ID: atributos[0],
            codigoSAP: atributos[25],
            numeroSerie: atributos[1],
            nombreEquipo: atributos[2],
            descripcion: atributos[3],
            fabricante: atributos[4],
            proveedor: atributos[5],
            modelo: atributos[6],
            ubicacion: atributos[7],
            // ubicacion: 'Central',
            // ubicacion: 'S/E Ancud',
            // ubicacion: atributos[7],
            divisionAreaResponsable: "",
            gerenciaAreaResponsable: "",
            areaResponsable: atributos[8],
            comentarios: atributos[9],
            idContrato: atributos[10],
            anoFabricacion: atributos[11],
            fechaPES: atributos[12],
            mesesGarantia: atributos[13],
            fechaFinGarantia: atributos[14],
            expiracionGarantia: new Date(atributos[15]).getTime(),
            listImagenes: atributos[16],
            listAdjuntos: atributos[17],
            listDocumentos: atributos[18],
            categoria: atributos[19],
            criticidad: atributos[20],
            tipo: atributos[21],
            subtipo: atributos[22],
            codigoEstructura: atributos[23],
            ID2: atributos[24],
            condicionEspecialGarantia: atributos[26],
            IDProveedor: atributos[27],
            contactoProveedor: atributos[28],
            fonoProveedor: atributos[29],
            emailProveedor: atributos[30],
            TAG: atributos[31],
            ADC: atributos[32], //Admin de Contrato
            contrato: atributos[33], //Nombre Contrato
            ingenieroContrato: (atributos.length <= 34) ? '' : atributos[34],
            mailIngenieroContrato: (atributos.length <= 35) ? '' : atributos[35],
            proyecto: (atributos.length <= 36) ? '' : atributos[36], //Nombre proyecto
            nombreCortoEquipo: (atributos.length <= 37) ? '' : atributos[37], //Nombre corto equipo (40 char max idealmente)
            plano: (atributos.length <= 38) ? '' : atributos[38],
            TagEquipoPrincipal: (atributos.length <= 39) ? '' : atributos[39],
            // urlQR: this.urlBase = "/#/cl/" + newPost.key,
            // urlQR: "https://e-garant-clientes.web.app/#/cl/" + newPost.key,
            // urlQR: "https://e-garant-bhp.pcvuelat.com/#/cl/" + newPost.key,
            urlQR: "https://e-garant-bhp.pcvuelat.com/#/item/" + newPost.key, //BHP - LSTS-WP03
          }

          newPost.set(newElement);

          // Transelec
          // console.log("%s, %s, %s, https://transelec.pcvuelat.com/#/cl/%s", atributos[0], atributos[1], atributos[2], newPost.key);
          // BHP
          // console.log("%s,%s,%s,%s", newElement.proyecto, newElement.nombreCortoEquipo, newElement.TAG, newElement.urlQR);
          // BHP - LSTS-WP03
          console.log("%s,%s,%s,%s,%s", newElement.proyecto, newElement.nombreCortoEquipo, newElement.TAG, newElement.plano, newElement.urlQR);
        }
        else {
          console.log("line (%s) no cumple el patron", line);
        }
      });
    },
  },

  created() {

    let unsuscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.useremail = user.email;
        // console.log("USER SIGNED");
        // console.log("this->", this);
        // this.initTables(user);
        // localStorage.setItem("token", response.data.tokenDeAcceso);

        this.loadData();

      } else {
        unsuscribe();
        console.log("USER NOT SIGNED");
        this.$router.push("/login");
      }
    });

  },

  mounted(){
  },
};
</script>
<style></style>
