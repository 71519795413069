import { createRouter, createWebHashHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import DashboardLayoutRepairco from "@/layout/DashboardLayoutRepairco";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Profile from "../views/UserProfile.vue";
import Tables from "../views/Tables.vue";
import LoadData from "../views/LoadData.vue";
import Usuarios from "../views/Usuarios.vue";
import Element from "../views/Element.vue";
import Doc from "../views/Doc.vue";
import InboxSolicitudes from "../views/InboxSolicitudes.vue";

import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import Register from "../views/Register.vue";

import UploadData from "../views/UploadData.vue";
import OpenPdf from "../views/OpenPdf.vue";

// import Icons from "../demo/Icons.vue";
// import Maps from "../demo/Maps.vue";

// import PageVisitsTable from "../demo/Dashboard/PageVisitsTable.vue";
// import SocialTrafficTable from "../demo/Dashboard/SocialTrafficTable.vue";

const routes = [
  // {
  //   path: "/uploaddata/:keydb/:datavalue",
  //   //redirect: "/uploaddata",
  //   component: UploadData,
  //   props:true
  // },
  {
    path: "/",
    redirect: "/tables",
    component: DashboardLayout,
    children: [
      // {
      //   path: "/icons",
      //   name: "icons",
      //   components: { default: Icons },
      // },
      // {
      //   path: "/maps",
      //   name: "maps",
      //   components: { default: Maps },
      // },
      // {
      //   path: "/pagevisits",
      //   name: "pagevisits",
      //   components: { default: PageVisitsTable },
      // },
      // {
      //   path: "/SocialTrafficTable",
      //   name: "SocialTrafficTable",
      //   components: { default: SocialTrafficTable },
      // },
      
      {
        path: "/uploaddata/:keydb/:datavalue",
        name: "uploaddata",
        components: { default: UploadData },
        props:true
      },
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/profile",
        name: "profile",
        components: { default: Profile },
      },
      {
        path: "/tables",
        name: "Equipos",
        components: { default: Tables },
      },
      {
        path: "/loadData",
        name: "Carga de datos",
        components: { default: LoadData },
      },
      {
        path: "/usuarios",
        name: "Usuarios",
        components: { default: Usuarios },
      },
      {
        path: "/element/:elementid",
        name: "Detalle equipo",
        components: { default: Element },
        props:true
      },
      {
        path: "/cl/:elementid",
        name: "Datos equipo",
        components: { default: Element },
        props:true
      },
      {
        path: "/item/:elementid",
        name: "Informacion equipo",
        // components: { default: Element },
        // props: { default: { projectID: "LSTSWP03" } }
        component: Element,
        props: { baseInventario: "LSTSWP03" }
        // props: { baseInventario: "LSTSWP02" }
        // props:true
      },
      {
        path: "/doc/:elementid",
        name: "Carta de garantía",
        components: { default: Doc },
        props:true
      },
      {
        path: "/solicitudes",
        name: "solicitudes",
        components: { default: InboxSolicitudes },
      },
      {
        path: "/solicitudes/:elementid",
        name: "Detalle solicitud",
        components: { default: InboxSolicitudes },
      },
    ],
  },
  // {
  //   path: "/",
  //   // redirect: "/pdf",
  //   component: DashboardLayoutRepairco,
  //   children: [
  //     {
  //       path: "/OpenPdf/:elementid/:tipo/:nombrearchivo",
  //       name: "OpenPdf",
  //       components: { default: OpenPdf },
  //       props:true
  //     },
  //     {
  //       path: "/OpenPdf2",
  //       name: "OpenPdf2",
  //       components: { default: OpenPdf },
  //       props:true
  //     },
  //   ],
  // },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      },
    ],
  },
  {
    path: "/",
    redirect: "logout",
    component: AuthLayout,
    children: [
      {
        path: "/logout",
        name: "logout",
        components: { default: Logout },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
