<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Ha cerrado la sesión exitosamente</small>
          </div>
          <form role="form">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-bind:value="model.email"
              v-on:input="setEmail"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Contraseña"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              
              v-on:input="setPassword"
            >
            </base-input>

            <!--<base-checkbox class="custom-control-alternative">
              <span class="text-muted">Recordarme</span>
            </base-checkbox>-->
            <div class="text-center">
              <base-button type="primary" class="my-4" @click.prevent="sendLogin()">Ingrese</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <!-- <a href="#" class="text-light"><small>Contraseña olvidada?</small></a> -->
        </div>
        <div class="col-6 text-right">
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from 'firebase';
export default {
  
  name: "login",

  data() {
    return {
    memail:"",  
    model: {
        email: "",
        password: "",
      },
    };
  },
   methods: {

     setEmail(event)
     {
       console.log("setEmail");
       this.model.email= event.target.value;
     },
     setPassword(event)
     {
       console.log("setPassword");
       this.model.password= event.target.value;
     },

    sendLogin() {

      console.log("sending...");
      console.log(this.model.email);
      console.log(this.model.password);
      
      firebase
      .auth()
      .signInWithEmailAndPassword(this.model.email, this.model.password)
      .then(() => {
        console.log("loggedin");
        this.$router.push('/tables');
      })
      .catch(error => {
        alert(error.message);
      });

    },
    Logout() {

      console.log("sending logout...");

      
      firebase
      .auth().signOut()
      .then(() => {
        console.log("signout");
        
      })
      .catch(error => {
        alert(error.message);
      });

    }
  },

  mounted() {
    this.Logout();
  }
};
</script>
<style></style>
