<template>
  <stats-card
    :title="title"
    type="gradient-green"
    :sub-title="subtitle"
    icon="ni ni-active-40"
    class="mb-4 mb-xl-3 w-100"
  >
    <template v-slot:footer>
      <div id="chart" class="mb-5">
        <apexchart type="line" height="350" :options="chartOptionsAltDay" :series="seriesAlturaDay"></apexchart>
      </div>
<!-- 
      <div id="chart" class="mb-5">
        <apexchart type="line" height="350" :options="chartOptionsAltWeek" :series="seriesAlturaWeek"></apexchart>
      </div>
      <div id="chart" class="mb-5">
        <apexchart type="line" height="350" :options="chartOptionsAltMonth" :series="seriesAlturaMonth"></apexchart>
      </div>
 -->
    </template>
  </stats-card>
</template>
<script>

import VueApexCharts from "vue3-apexcharts";
import { getDeviceData } from "@/model/functions_powerline-ms";

export default {
  name: "powerlinems-altura-equipos",

  components: {
    apexchart: VueApexCharts,
  },

  props: {
    title: {
      type: String,
      default: "Altura equipo",
    },
    subtitle: {
      type: String,
      default: "",
    },
    
    deviceID: {
      type: String,
      default: "",
    },

    series: {
      type: Array,
      default: [
        {
          name: "High - 2013+",
          data: [28, 29, 33, 36, 32, 32, 33]
        },
        {
          name: "Low - 2013",
          data: [12, 11, 14, 18, 17, 13, 13]
        }
      ],
    },    
  },

  data() {
    return {
      seriesAltura: [],
      // seriesTemp: [],

      seriesAlturaDay: [],
      seriesAlturaWeek: [],
      seriesAlturaMonth: [],

      chartOptionsAlt: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454', '#345474'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: this.chartTitle,
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Horario'
          }
          // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
          // title: {
          //   text: 'Month'
          // }
        },
        yaxis: {
          title: {
            text: 'Altura'
          },
          // min: 5,
          // max: 40
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },

      chartOptionsAltDay: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454', '#345474'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: "Altura últimas 24 horas",
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Horario'
          }
        },
        yaxis: {
          title: {
            text: 'Altura'
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },
      
      chartOptionsAltWeek: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454', '#345474'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: "Altura últimos 7 días",
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Horario'
          }
        },
        yaxis: {
          title: {
            text: 'Altura'
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },

      chartOptionsAltMonth: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#545454', '#345474'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: "Altura últimos 30 días",
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Horario'
          }
        },
        yaxis: {
          title: {
            text: 'Altura'
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },
      
    };
  },

  methods: {},

  created() { //when the website mounts...starts

    getDeviceData(this.db_ref_stuMessages, this.deviceID, (datos) => {
      // console.log("datos -->", datos);        
      const seriesAltura = [
        {
          name: "Altura Actual",
          data: []
        },
        // {
        //   name: "Altura Anterior",
        //   data: []
        // },
      ]

      this.seriesAltura = JSON.parse(JSON.stringify(seriesAltura));
      this.seriesAlturaDay = JSON.parse(JSON.stringify(seriesAltura));
      this.seriesAlturaWeek = JSON.parse(JSON.stringify(seriesAltura));
      this.seriesAlturaMonth = JSON.parse(JSON.stringify(seriesAltura));

      const utc_offset = 3*3600000;

      const offset_day = 24*3600000;
      const offset_week = offset_day * 7;
      const offset_month = offset_day * 30;

      this.seriesAlturaDay[0].data.push([new Date(new Date().getTime() - utc_offset - offset_day), null]);
      this.seriesAlturaDay[0].data.push([new Date(new Date().getTime() - utc_offset), null]);
      // this.seriesAlturaDay[1].data.push([new Date(new Date().getTime() - utc_offset - offset_day), null]);
      // this.seriesAlturaDay[1].data.push([new Date(new Date().getTime() - utc_offset), null]);

      this.seriesAlturaWeek[0].data.push([new Date(new Date().getTime() - utc_offset), null]);
      // this.seriesAlturaWeek[1].data.push([new Date(new Date().getTime() - utc_offset), null]);

      this.seriesAlturaMonth[0].data.push([new Date(new Date().getTime() - utc_offset), null]);
      // this.seriesAlturaMonth[1].data.push([new Date(new Date().getTime() - utc_offset), null]);

      let ultimaAlturaValida = null;

      datos.forEach(element => {
        // console.log("element -->", element);

        // if ((new Date.now().getTime()) < element.timestamp && element.timestamp < (new Date('21 Nov 2023 GMT').getTime()))
        // if ((new Date('19 Nov 2023 GMT').getTime()) < element.timestamp && element.timestamp < (new Date('21 Nov 2023 GMT').getTime()))

        let alturaActual = null;
        if ((element.data.alturaActual * 10) != 0xFFFF && (element.data.alturaActual * 10) != 0xBF20) {
          if(ultimaAlturaValida != null && ((ultimaAlturaValida - element.data.alturaActual)/100.0) > 5)
          {
            alturaActual = ultimaAlturaValida; //Si existe una disminución de altura mayor a 5 metros, se considera lectura no válida y nos quedamos con el último dato válido.
          }
          else
          {
            alturaActual = element.data.alturaActual;
            ultimaAlturaValida = alturaActual;
          }
        }
        if ((element.data.alturaActual * 10) == 0xBF20) {
          alturaActual = ultimaAlturaValida;
        }
        
        if ((new Date().getTime() - offset_day) < element.timestamp)
        {
          // Altura
          if (alturaActual != null)
            this.seriesAlturaDay[0].data.push([new Date(element.timestamp - utc_offset), (alturaActual / 100.0).toFixed(2)]);

          // if ((element.data.alturaAnterior * 10) != 0xFFFF)
          //   this.seriesAlturaDay[1].data.push([new Date(element.timestamp - utc_offset), (element.data.alturaAnterior / 100.0)]);
        }

        if ((new Date().getTime() - offset_week) < element.timestamp)
        {
          // Altura
          if (alturaActual != null)
            this.seriesAlturaWeek[0].data.push([new Date(element.timestamp - utc_offset), (alturaActual / 100.0).toFixed(2)]);

          // if ((element.data.alturaAnterior * 10) != 0xFFFF)
          //   this.seriesAlturaWeek[1].data.push([new Date(element.timestamp - utc_offset), (element.data.alturaAnterior / 100.0)]);
        }

        if ((new Date().getTime() - offset_month) < element.timestamp)
        {
          // Altura
          if (alturaActual != null)
            this.seriesAlturaMonth[0].data.push([new Date(element.timestamp - utc_offset), (alturaActual / 100.0).toFixed(2)]);

          // if ((element.data.alturaAnterior * 10) != 0xFFFF)
          //   this.seriesAlturaMonth[1].data.push([new Date(element.timestamp - utc_offset), (element.data.alturaAnterior / 100.0)]);
        }
      });
    });    
  }
};
</script>
<style></style>
