<template>
  <div>
    <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
      <link
        href="https://unpkg.com/primevue/resources/themes/saga-blue/theme.css"
        rel="stylesheet"
      />
      <link
        href="https://unpkg.com/primevue/resources/primevue.min.css"
        rel="stylesheet"
      />
      <link
        href="https://unpkg.com/primeicons/primeicons.css"
        rel="stylesheet"
      />
      <!-- Card stats -->
      <!--
      <div class="row">
        <div class="col-xl-6 col-lg-6">
          <stats-card
            title="Element ID"
            type="gradient-red"
            v-bind:sub-title="elementid"
            icon="ni ni-bold-left"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <h5>Equipo</h5>
              <div class="p-text-capitalize">{{ Equipment }}</div>
              <h5>PO</h5>
              <div class="p-text-capitalize">{{ POrder }}</div>
              <h5>Supplier</h5>
              <div class="p-text-capitalize">{{ Supplier }}</div>
              <h5>Spare type</h5>
              <div class="p-text-capitalize">{{ SpareType }}</div>
              <h5>Part number</h5>
              <div class="p-text-capitalize">{{ PartNumber }}</div>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-2 col-lg-6">
          <stats-card
            title="Total items"
            type="gradient-red"
            v-bind:sub-title="totalitems"
            icon="ni ni-active-40"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-nowrap">Elementos en lista</span>
            </template>
          </stats-card>
        </div>
      </div>
       -->
      <div id="app" style="background-color: white">
        <vue-editor id="editor" v-model="content"></vue-editor>
      </div>
      <div class="my-2">
        <Button label="Generar carta" class="p-button-raised p-button-success" @click="procesarSolicitud"/>
      </div>
    </base-header>
  </div>
</template>
<script>
import firebase from "firebase";
import "firebase/database"; // If using Firebase database
import "firebase/storage"; // If using Firebase storage
import Button from "primevue/button";
import { VueEditor } from "vue3-editor";

import "primeflex/primeflex.css";

export default {
  components: {
    Button,
    VueEditor,
  },
  data() {
    return {
      content: "",
      totalitems: 0,

      elementid: this.$route.params.elementid,
      datosSolicitud: "",
    };
  },
  methods: {
    procesarSolicitud(){
      console.log("procesarSolicitud", "solicitudes/" + this.elementid);
      firebase.database().ref("solicitudes/" + this.elementid).update({ estado: "en proceso" });
      this.$router.push("/solicitudes");
    },
  },

  mounted() {
    //READING PARAMETER
    console.log(this.elementid);
    firebase.database().ref("solicitudes/" + this.elementid).on('value', (childSnapshot) => { //"inventario/SE Ancud/"
      if(childSnapshot.exists())
      {
        this.datosSolicitud = childSnapshot.val();
        this.content =
              `<h1>Carta de garantía</h1> \
<p>Estimado(s) ${this.datosSolicitud.proveedor}, </p> \
<p>Hacemos llegar la carta presente para hacer valer garantía para el equipo ${this.datosSolicitud.nombreEquipo} \
asociado al contrato ${this.datosSolicitud.idContrato} \
expirando el ${this.datosSolicitud.fechaFinGarantia}. </p> \
<strong>Descripción falla</strong> \
${this.datosSolicitud.reporteFalla} <br> \
<strong>Transelec</strong> \
Orinoco 90, Las Condes, Región Metropolitana`;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.shadow-box {
  min-height: 100px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin: 2rem;
  border-radius: 4px;
  background-color: var(--surface-e);
}

.p-button {
  margin-right: 0.5rem;
}

.p-buttonset {
  .p-button {
    margin-right: 0;
  }
}

.sizes {
  .button {
    margin-bottom: 0.5rem;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
